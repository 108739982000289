import { db } from "../firebase";
import firebase from "firebase/app";

const actions = {
  async getChallenges() {
    try {
      const challenges = await db
        .collection("challenges")
        .where("isValid", "==", false)
        .get();
      let arrayChallenges = [];
      challenges.docs.forEach((challenge) => {
        const obj = {
          id: challenge.id,
          ...challenge.data(),
        };
        arrayChallenges.push(obj);
      });
      return {
        success: true,
        challenges: arrayChallenges,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getDCMChallenges() {
    try {
      const challengesP02 = await db
        .collection("dcm_challenges")
        .where("isReviewed", "==", false)
        .where("challengeId", "==", "p02")
        .where("isCompleted", "==", true)
        .get();
      let arrayChallengesP02 = [];
      challengesP02.docs.forEach((challenge) => {
        const photosFormatted = challenge.data().photos
          ? challenge.data().photos.sort((a, b) => a.invoice - b.invoice)
          : [];
        const invoicesFormatted = challenge.data().invoices
          ? challenge.data().invoices.sort((a, b) => a.invoice - b.invoice)
          : [];
        const obj = {
          id: challenge.id,
          ...challenge.data(),
          photosFormatted,
          invoicesFormatted,
        };
        arrayChallengesP02.push(obj);
      });
      return {
        success: true,
        challengesP02: arrayChallengesP02,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async setDCMChallengePoint({}, challenge) {
    try {
      const batch = db.batch();
      batch.update(db.collection("dcm_challenges").doc(challenge.id), {
        isValid: true,
        isReviewed: true,
        reviewedAt: Date.now(),
      });
      await batch.commit();
      return {
        success: true,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async rejectDCMChallengePoint({}, challengeId) {
    try {
      await db
        .collection("dcm_challenges")
        .doc(challengeId)
        .update({ isReviewed: true, reviewedAt: Date.now() });
      return {
        success: true,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async setChristmasChallengeThreePoint({}, data) {
    const challenge = {
      uid: data.uid,
      challengeId: "3",
      companyId: data.companyId,
      companyName: data.companyName,
      createdAt: Date.now(),
      reviewedAt: Date.now(),
      isReviewed: true,
      isValid: true,
      role: data.role,
      vip: data.vip,
    };

    try {
      const batch = db.batch();
      batch.set(db.collection("christmas_challenges").doc(), challenge);
      batch.update(db.collection("--stats--").doc("stats"), {
        challenge_christmas_three: firebase.firestore.FieldValue.increment(1),
      });
      await batch.commit();
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getDCMChallengesP03() {
    try {
      const challengesP03 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p03")
        .get();

      let arrayChallengesP03 = [];
      challengesP03.docs.forEach((challenge) => {
        const invoicesFormatted = challenge.data().invoices
          ? challenge.data().invoices.sort((a, b) => a.invoice - b.invoice)
          : [];
        const obj = {
          id: challenge.id,
          ...challenge.data(),
          invoicesFormatted,
        };
        arrayChallengesP03.push(obj);
      });

      let finalArray = [];
      arrayChallengesP03.map((user) => {
        //console.log(user.isCompleted)
        const invoices = user.invoices;
        function isOver(invoices) {
          return !invoices.isReviewed;
        }
        const someFalse = invoices.some(isOver);

        if (
          someFalse ||
          (user.isCompleted && !user.isReviewed) ||
          !user.video
        ) {
          let stateChallenge = "";
          if (user.isValid && user.isReviewed) {
            stateChallenge = "Aceptado";
          } else if (!user.isValid && user.isReviewed) {
            stateChallenge = "Rechazado";
          } else if (!user.isValid && !user.isReviewed) {
            stateChallenge = "Pendiente";
          }

          const newObj = {
            challengeId: user.challengeId,
            uid: user.uid,
            createdAt: user.createdAt,
            id: user.id,
            invoiceReview: someFalse,
            video: user.video ? user.video : false,
            state: stateChallenge,
          };

          if (stateChallenge !== "Rechazado") {
            finalArray.push(newObj);
          }
        }
      });
      return {
        success: true,
        challengesP03: finalArray,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getDCMChallengesUserP03({}, id) {
    try {
      const challengesUserP03 = await db
        .collection("dcm_challenges")
        .doc(id)
        .get();

      const challenges = challengesUserP03.data();
      const invoicesUser = challenges.invoices.map((invoice) => {
        return invoice;
      });
      return {
        success: true,
        challengesUserP03: challenges,
        invoices: invoicesUser,
        challengeId: challengesUserP03.id, //Por defecto en Firebase
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async updateInvoiceP03({}, payload) {
    try {
      const batch = db.batch();
      batch.update(
        db.collection("dcm_challenges").doc(payload.challengeUserId),
        {
          invoices: payload.array,
        }
      );
      await batch.commit();
      return {
        success: true,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async rejectInvoiceP03({}, payload) {
    try {
      const batch = db.batch();
      batch.update(
        db.collection("dcm_challenges").doc(payload.challengeUserId),
        {
          invoices: payload.array,
          isCompleted: false,
        }
      );
      await batch.commit();
      return {
        success: true,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async acceptChallengeP03({}, payload) {
    try {
      const batch = db.batch();
      batch.update(
        db.collection("dcm_challenges").doc(payload.challengeUserId),
        {
          isReviewed: true,
          isValid: true,
          reviewedAt: Date.now(),
        }
      );
      await batch.commit();
      return {
        success: true,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async rejectChallengeP03({}, payload) {
    try {
      const batch = db.batch();
      batch.update(
        db.collection("dcm_challenges").doc(payload.challengeUserId),
        {
          isReviewed: true,
          isValid: false,
          reviewedAt: Date.now(),
        }
      );
      await batch.commit();
      return {
        success: true,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getDCMChallengesP05() {
    try {
      const challengesP05 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p05")
        .get();

      let arrayChallengesP05 = [];

      if (challengesP05.docs.length) {
        challengesP05.docs.forEach((challenge) => {
          const obj = {
            id: challenge.id,
            ...challenge.data(),
          };
          arrayChallengesP05.push(obj);
        });
      }
      return {
        success: true,
        challengesP05: arrayChallengesP05,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async acceptChallengeP05({}, payload) {
    try {
      const batch = db.batch();
      batch.set(db.collection("dcm_challenges").doc(), payload);
      batch.update(db.collection("--stats--").doc("stats"), {
        challenge_dcm_p05: firebase.firestore.FieldValue.increment(1),
      });
      await batch.commit();
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async acceptChallengeP06({}, payload) {
    try {
      const batch = db.batch();
      batch.set(db.collection("dcm_challenges").doc(), payload);
      batch.update(db.collection("--stats--").doc("stats"), {
        challenge_dcm_p06: firebase.firestore.FieldValue.increment(1),
      });
      await batch.commit();
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async acceptChallengeP07({}, payload) {
    try {
      const batch = db.batch();
      batch.set(db.collection("dcm_challenges").doc(), payload);
      batch.update(db.collection("--stats--").doc("stats"), {
        challenge_dcm_p07: firebase.firestore.FieldValue.increment(1),
      });
      await batch.commit();
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async acceptChallengeP10({}, userData) {
    try {
      const challengeFb = await db
        .collection("challenges_2024")
        .where("uid", "==", userData.uid)
        .where("challengeId", "==", "p10")
        .get();

      if (challengeFb.empty) {
        const obj = {
          challengeId: "p10",
          companyId: userData.companyId,
          companyName: userData.companyName,
          createdAt: Date.now(),
          role: userData.role,
          uid: userData.uid,
          year: "2024",
          part_3: {
            isValid: true,
            isCompleted: true,
            isReviewed: true,
          },
          isCompleted: false,
          isReviewed: false,
          isValid: false,
        };

        await db.collection("challenges_2024").add(obj);

        return {
          success: true,
          message: "",
        };
      } else {
        const challengeData = challengeFb.docs[0].data();

        if (challengeData.isCompleted || !!challengeData.part_3) {
          return {
            success: false,
            message: "La parte 3 ya ha sido aceptada",
          };
        }

        const batch = db.batch();
        let isCompleted = false;
        let isReviewed = false;
        let isValid = false;

        if (
          !!challengeData.part_1 &&
          !!challengeData.part_2 &&
          !!challengeData.part_4
        ) {
          isCompleted = true;
          isReviewed = true;
          isValid =
            challengeData.part_1.isValid &&
            challengeData.part_2.isValid &&
            challengeData.part_4.isValid;

          batch.update(db.collection("--stats--").doc("stats"), {
            challenge_2024_p10: admin.firestore.FieldValue.increment(1),
          });
        }

        batch.update(
          db.collection("challenges_2024").doc(challengeFb.docs[0].id),
          {
            part_3: {
              isValid: true,
              isCompleted: true,
              isReviewed: true,
            },
            updatedAt: Date.now(),
            isCompleted,
            isReviewed,
            isValid,
          }
        );

        await batch.commit();
        return {
          success: true,
          message: "",
        };
      }
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async acceptChallengeP12({}, userData) {
    try {
      const challengeFb = await db
        .collection("challenges_2024")
        .where("uid", "==", userData.uid)
        .where("challengeId", "==", "p12")
        .get();

      if (challengeFb.empty) {
        const obj = {
          challengeId: "p12",
          companyId: userData.companyId,
          companyName: userData.companyName,
          createdAt: Date.now(),
          role: userData.role,
          uid: userData.uid,
          year: "2024",
          part_1: {
            isValid: true,
            isCompleted: true,
            isReviewed: true,
          },
          isCompleted: false,
          isReviewed: false,
          isValid: false,
        };

        await db.collection("challenges_2024").add(obj);

        return {
          success: true,
          message: "",
        };
      } else {
        const challengeData = challengeFb.docs[0].data();

        if (challengeData.isCompleted || !!challengeData.part_1) {
          return {
            success: false,
            message: "La parte 1 ya ha sido aceptada",
          };
        }

        const batch = db.batch();
        let isCompleted = false;
        let isReviewed = false;
        let isValid = false;

        if (!!challengeData.part_2 && !!challengeData.part_3) {
          isCompleted = true;
          isReviewed = true;
          isValid =
            challengeData.part_2.isValid && challengeData.part_3.isValid;

          batch.update(db.collection("--stats--").doc("stats"), {
            challenge_2024_p12: admin.firestore.FieldValue.increment(1),
          });
        }

        batch.update(
          db.collection("challenges_2024").doc(challengeFb.docs[0].id),
          {
            part_1: {
              isValid: true,
              isCompleted: true,
              isReviewed: true,
            },
            updatedAt: Date.now(),
            isCompleted,
            isReviewed,
            isValid,
          }
        );

        await batch.commit();
        return {
          success: true,
          message: "",
        };
      }
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getDCMChallengesP05ById({}, uid) {
    try {
      const challengesP05 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p05")
        .get();

      const arrayChallenge = [];
      let success = false;

      challengesP05.docs.forEach((challenge) => {
        if (challenge.data().uid === uid) {
          success = true;
        }
      });
      return {
        success: success,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getDCMChallengesP06ById({}, uid) {
    try {
      const challengesP06 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p06")
        .get();

      const arrayChallenge = [];
      let success = false;

      challengesP06.docs.forEach((challenge) => {
        if (challenge.data().uid === uid) {
          success = true;
        }
      });
      return {
        success: success,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getDCMChallengesP07ById({}, uid) {
    try {
      const challengesP06 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p07")
        .get();

      const arrayChallenge = [];
      let success = false;

      challengesP06.docs.forEach((challenge) => {
        if (challenge.data().uid === uid) {
          success = true;
        }
      });
      return {
        success: success,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getDCMChallengesP10ById({}, uid) {
    try {
      const userChallengeP10 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p10")
        .where("uid", "==", uid)
        .get();

      if (userChallengeP10.empty) {
        return {
          accepted: false,
        };
      } else {
        return {
          accepted: true,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        accepted: false,
        message: error.message,
      };
    }
  },
  async get2024P10Challenge({}, uid) {
    try {
      const userChallengeP10 = await db
        .collection("challenges_2024")
        .where("challengeId", "==", "p10")
        .where("uid", "==", uid)
        .get();

      if (userChallengeP10.empty) {
        return {
          accepted: false,
        };
      } else {
        const challengeData = userChallengeP10.docs[0].data();
        return {
          accepted: !!challengeData.part_3,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        accepted: false,
        message: error.message,
      };
    }
  },
  async get2024P12Challenge({}, uid) {
    try {
      const userChallengeP12 = await db
        .collection("challenges_2024")
        .where("challengeId", "==", "p12")
        .where("uid", "==", uid)
        .get();

      if (userChallengeP12.empty) {
        return {
          accepted: false,
        };
      } else {
        const challengeData = userChallengeP12.docs[0].data();
        return {
          accepted: !!challengeData.part_1,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        accepted: false,
        message: error.message,
      };
    }
  },

  async getCSChallengesP13ById({}, uid) {
    try {
      const userChallengeP13 = await db
        .collection("cs_challenges")
        .where("challengeId", "==", "p13")
        .where("uid", "==", uid)
        .get();

      if (userChallengeP13.empty) {
        return {
          success: true,
          value: null,
        };
      } else {
        return {
          success: true,
          value: {
            id: userChallengeP13.docs[0].id,
            data: userChallengeP13.docs[0].data(),
          },
        };
      }
    } catch (error) {
      console.error(error);
      return {
        success: false,
      };
    }
  },

  async getCSChallengesP13Part2() {
    try {
      const challengesP13Part2 = await db
        .collection("cs_challenges")
        .where("challengeId", "==", "p13")
        .get();

      if (challengesP13Part2.empty) {
        return { success: true, value: [] };
      } else {
        let challengesData = [];

        challengesP13Part2.forEach((doc) => {
          challengesData.push({ id: doc.id, data: doc.data() });
        });

        const challengesWithPart2 = challengesData.filter(
          (challenge) => challenge.data?.part_2
        );
        const challengesWithPart2NotReviewed = challengesWithPart2.filter(
          (challenge) => !challenge.data?.part_2?.isReviewed
        );

        return { success: true, value: challengesWithPart2NotReviewed };
      }
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  },

  async setChallengeP13Part1({}, { obj, action, challengeDocId }) {
    try {
      if (action === "create") {
        await db.collection("cs_challenges").add(obj);
      }

      if (action === "update") {
        const batch = db.batch();

        batch.update(db.collection("cs_challenges").doc(challengeDocId), obj);
        if (obj.isReviewed) {
          batch.update(db.collection("--stats--").doc("stats"), {
            challenge_cs_p13: firebase.firestore.FieldValue.increment(1),
          });
        }

        await batch.commit();
      }
    } catch (error) {
      console.error(error);
    }
  },

  async acceptVideoChallengeP03({}, payload) {
    try {
      const batch = db.batch();
      batch.update(
        db.collection("dcm_challenges").doc(payload.challengeUserId),
        {
          video: true,
          reviewedAt: Date.now(),
        }
      );
      await batch.commit();
      return {
        success: true,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
};

export default {
  namespaced: true,
  actions,
};
