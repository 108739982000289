import { db } from "../firebase";

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const actions = {
  async getUsers({}, to) {
    const XLSX = require("xlsx");
    const users = (
      await db
        .collection("users")
        .where("disabled", "==", false)
        .where("createdAt", "<=", to.myEpoch)
        .get()
    ).docs.map((doc) => doc.data());

    let companies = [];
    (
      await Promise.all(
        Array.from(new Set(users.map((user) => user.companyId))).map((cid) =>
          db.collection("companies").doc(cid).get()
        )
      )
    ).forEach((doc) => {
      if (doc.exists) {
        companies.push(doc.data());
      }
    });

    const array = users
      .filter((user) => user.companyName !== "OKANA")
      .map((user) => {
        let myDate = new Date(user.createdAt);
        const company = companies.find((c) => c.cid === user.companyId);
        return {
          CIUDAD: user.city
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          REGION: company?.region ?? "EMPRESA ELIMINADA",
          NIT: company?.nit ?? "EMPRESA ELIMINADA",
          TRACKSALES: company?.cid ?? "EMPRESA ELIMINADA",
          EMPRESA: company?.name ?? "EMPRESA ELIMINADA",
          CEDULA: user.cc,
          NOMBRE: user.name,
          EMAIL: user.email,
          CELULAR: user.phone,
          DIRECCION: user.address,
          ROL: user.role === "WORKER" ? "COLABORADOR" : "PROPIETARIO",
          NIVEL: user.level,
          FECHA: myDate.toLocaleDateString(),
          HORA: myDate.toLocaleTimeString(),
        };
      });
    const workSheet = XLSX.utils.json_to_sheet(array);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Usuarios");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "Propietarios_Colaboradores.xlsx");
  },
  async getUsersDates({}, to) {
    const XLSX = require("xlsx");
    const users = (
      await db
        .collection("users")
        .where("disabled", "==", false)
        .where("createdAt", ">=", to.myEpochInit)
        .where("createdAt", "<=", to.myEpochFinal)
        .get()
    ).docs.map((doc) => doc.data());

    let companies = [];
    (
      await Promise.all(
        Array.from(new Set(users.map((user) => user.companyId))).map((cid) =>
          db.collection("companies").doc(cid).get()
        )
      )
    ).forEach((doc) => {
      if (doc.exists) {
        companies.push(doc.data());
      }
    });

    const array = users
      .filter((user) => user.companyName !== "OKANA")
      .map((user) => {
        let myDate = new Date(user.createdAt);
        const company = companies.find((c) => c.cid === user.companyId);
        return {
          CIUDAD: user.city
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          REGION: company?.region ?? "EMPRESA ELIMINADA",
          NIT: company?.nit ?? "EMPRESA ELIMINADA",
          TRACKSALES: company?.cid ?? "EMPRESA ELIMINADA",
          EMPRESA: company?.name ?? "EMPRESA ELIMINADA",
          CEDULA: user.cc,
          NOMBRE: user.name,
          EMAIL: user.email,
          CELULAR: user.phone,
          DIRECCION: user.address,
          ROL: user.role === "WORKER" ? "COLABORADOR" : "PROPIETARIO",
          NIVEL: user.level,
          FECHA: myDate.toLocaleDateString(),
          HORA: myDate.toLocaleTimeString(),
        };
      });
    const workSheet = XLSX.utils.json_to_sheet(array);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Usuarios");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "Registrados_Propietarios_Colaboradores.xlsx");
  },

  async getUsersInactive({}, to) {
    const XLSX = require("xlsx");
    const users = (
      await db
        .collection("users")
        .where("disabled", "==", true)
        .where("createdAt", "<=", to.myEpoch)
        .get()
    ).docs.map((doc) => doc.data());
    const usersFiltered = users.filter((user) => user.companyName !== "OKANA");

    let companies = [];
    (
      await Promise.all(
        Array.from(new Set(users.map((user) => user.companyId))).map((cid) =>
          db.collection("companies").doc(cid).get()
        )
      )
    ).forEach((doc) => {
      if (doc.exists) {
        companies.push(doc.data());
      }
    });

    const array = usersFiltered.map((user) => {
      let myDate = new Date(user.createdAt);
      const company = companies.find((c) => c.cid === user.companyId);
      return {
        CIUDAD: user.city
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""),
        REGION: company?.region ?? "EMPRESA ELIMINADA",
        NIT: company?.nit ?? "EMPRESA ELIMINADA",
        TRACKSALES: company?.cid ?? "EMPRESA ELIMINADA",
        EMPRESA: company?.name ?? "EMPRESA ELIMINADA",
        CEDULA: user.cc,
        NOMBRE: user.name,
        EMAIL: user.email,
        CELULAR: user.phone,
        DIRECCION: user.address,
        FECHA: myDate.toLocaleDateString(),
        HORA: myDate.toLocaleTimeString(),
        ROL: user.role === "WORKER" ? "COLABORADOR" : "PROPIETARIO",
      };
    });
    const workSheet = XLSX.utils.json_to_sheet(array);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Usuarios");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      "REPORTE COLABORADORES Y PROPIETARIOS ELIMINADOS.xlsx"
    );
  },

  async getChallengeP02({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesP02 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p02")
        .get();

      const users = await db
        .collection("users")
        .where("companyName", "!=", "OKANA")
        .get();

      let usersAll = [];
      users.forEach((user) => usersAll.push(user.data()));

      //usersAll.map(user => console.log(user))

      let arrayChallengesP02 = [];
      challengesP02.docs.forEach((challenge) => {
        const user = usersAll.filter((user) => {
          return user.uid === challenge.data().uid;
        });
        let estado = "";
        if (
          challenge.data().isValid &&
          challenge.data().isCompleted &&
          challenge.data().isReviewed
        ) {
          estado = "Aceptado";
        } else if (
          !challenge.data().isValid &&
          challenge.data().isCompleted &&
          challenge.data().isReviewed
        ) {
          estado = "Rechazado";
        } else {
          estado = "Incompleto";
        }

        if (user.length > 0) {
          const obj = {
            CIUDAD: user[0].city,
            EMPRESA: challenge.data().companyName,
            NOMBRE: user[0].name,
            EMAIL: user[0].email,
            FACTURAS: challenge.data().invoices
              ? challenge.data().invoices.length
              : "0",
            FOTOS: challenge.data().photos ? challenge.data().photos.length : 0,
            ESTADO: estado,
          };
          arrayChallengesP02.push(obj);
        }
      });

      const workSheet = XLSX.utils.json_to_sheet(arrayChallengesP02);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P02");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P02.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP03({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP03 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p03")
        .get();

      const users = await db
        .collection("users")
        .where("companyName", "!=", "OKANA")
        .get();

      let usersAll = [];
      users.forEach((user) => usersAll.push(user.data()));

      //usersAll.map(user => console.log(user))

      let arrayChallengesP03 = [];
      challengesP03.docs.forEach((challenge) => {
        let accept = 0;
        let reject = 0;
        challenge.data().invoices.map((invoice) => {
          if (invoice.isReviewed && invoice.isValid) {
            accept += 1;
          }
          if (invoice.isReviewed && !invoice.isValid) {
            reject += 1;
          }
        });

        const user = usersAll.filter((user) => {
          return user.uid === challenge.data().uid;
        });

        // console.log(user.name)
        let estado = "Pendiente";

        if (challenge.data().isReviewed && challenge.data().isValid) {
          estado = "Aceptado";
        }
        if (challenge.data().isReviewed && !challenge.data().isValid) {
          estado = "Rechazado";
        }

        if (user.length > 0) {
          const obj = {
            CIUDAD: user[0].city,
            EMPRESA: challenge.data().companyName,
            NOMBRE: user[0].name,
            EMAIL: user[0].email,
            FACTURAS: challenge.data().invoices.length,
            ACEPTADAS: accept,
            RECHAZADAS: reject,
            VIDEO: challenge.data().video ? "Aceptado" : "Pendiente",
            ESTADO_RETO: estado,
          };
          arrayChallengesP03.push(obj);
        }
      });

      const workSheet = XLSX.utils.json_to_sheet(arrayChallengesP03);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P03");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P03.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP04({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP04 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p04")
        .get();

      const users = await db
        .collection("users")
        .where("companyName", "!=", "OKANA")
        .get();

      let usersAll = [];
      users.forEach((user) => usersAll.push(user.data()));

      //usersAll.map(user => console.log(user))

      let arrayChallengesP04 = [];
      challengesP04.docs.forEach((challenge) => {
        const user = usersAll.filter((user) => {
          return user.uid === challenge.data().uid;
        });

        if (user.length > 0) {
          const obj = {
            CIUDAD: user[0].city,
            EMPRESA: challenge.data().companyName,
            NOMBRE: user[0].name,
            EMAIL: user[0].email,
            PORCENTAJE: challenge.data().percent,
            ESTADO_RETO:
              challenge.data().percent >= 80 ? "APROBADO" : "RECHAZADO",
          };
          arrayChallengesP04.push(obj);
        }
      });

      const workSheet = XLSX.utils.json_to_sheet(arrayChallengesP04);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P04");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P04.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP05({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP05 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p05")
        .get();

      const users = await db
        .collection("users")
        .where("companyName", "!=", "OKANA")
        .get();

      let usersAll = [];
      users.forEach((user) => usersAll.push(user.data()));

      //usersAll.map(user => console.log(user))

      let arrayChallengesP05 = [];
      challengesP05.docs.forEach((challenge) => {
        const user = usersAll.filter((user) => {
          return user.uid === challenge.data().uid;
        });

        if (user.length > 0) {
          const obj = {
            CIUDAD: user[0].city.toUpperCase(),
            EMPRESA: challenge.data().companyName,
            TRACKSALE: user[0].companyId,
            NOMBRE: user[0].name,
            EMAIL: user[0].email,
          };
          arrayChallengesP05.push(obj);
        }
      });

      const workSheet = XLSX.utils.json_to_sheet(arrayChallengesP05);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P05");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P05.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP06({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP06 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p06")
        .get();

      let challengesP06Data = [];
      challengesP06.forEach((doc) => {
        challengesP06Data.push(doc.data());
      });

      let reportData = [];

      await asyncForEach(challengesP06Data, async (challengeDoc) => {
        const uid = challengeDoc.uid;
        const companyId = challengeDoc.companyId;
        const docToPush = {};

        try {
          const doc = await db.collection("users").doc(uid).get();
          if (doc.exists) {
            docToPush.CIUDAD = doc.data().city.toUpperCase();
            docToPush.NOMBRE = doc.data().name;
            docToPush.EMAIL = doc.data().email;
          }
        } catch (error) {
          console.error(error);
        }

        try {
          const doc = await db.collection("companies").doc(companyId).get();
          if (doc.exists) {
            docToPush.EMPRESA = doc.data().name;
            docToPush.TRACKSALES = doc.data().cid;
            docToPush.ASESOR = doc.data().salesman;
            docToPush.DEPENDIENTES = doc.data().workers;
          }
        } catch (error) {
          console.error(error);
        }

        reportData.push(docToPush);
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P06");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P06.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP07({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP07 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p07")
        .get();

      const users = await db
        .collection("users")
        .where("companyName", "!=", "OKANA")
        .get();

      let usersAll = [];
      users.forEach((user) => usersAll.push(user.data()));

      //usersAll.map(user => console.log(user))

      let arrayChallengesP07 = [];
      challengesP07.docs.forEach((challenge) => {
        const user = usersAll.filter((user) => {
          return user.uid === challenge.data().uid;
        });

        if (user.length > 0) {
          const obj = {
            CIUDAD: user[0].city.toUpperCase(),
            EMPRESA: challenge.data().companyName,
            TRACKSALE: user[0].companyId,
            NOMBRE: user[0].name,
            EMAIL: user[0].email,
          };
          arrayChallengesP07.push(obj);
        }
      });

      const workSheet = XLSX.utils.json_to_sheet(arrayChallengesP07);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P07");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P07.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP08({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP08 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p08")
        .get();

      let challengesP08Data = [];
      challengesP08.forEach((doc) => {
        challengesP08Data.push(doc.data());
      });

      let reportData = [];

      await asyncForEach(challengesP08Data, async (challengeDoc) => {
        const uid = challengeDoc.uid;
        const companyId = challengeDoc.companyId;
        const docToPush = { APROBADO: challengeDoc.isValid ? "SI" : "NO" };

        try {
          const doc = await db.collection("users").doc(uid).get();
          if (doc.exists) {
            docToPush.CIUDAD = doc.data().city.toUpperCase();
            docToPush.NOMBRE = doc.data().name;
            docToPush.EMAIL = doc.data().email;
          }
        } catch (error) {
          console.error(error);
        }

        try {
          const doc = await db.collection("companies").doc(companyId).get();
          if (doc.exists) {
            docToPush.EMPRESA = doc.data().name;
            docToPush.TRACKSALES = doc.data().cid;
            docToPush.ASESOR = doc.data().salesman;
            docToPush.DEPENDIENTES = doc.data().workers;
          }
        } catch (error) {
          console.error(error);
        }

        reportData.push(docToPush);
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P08");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P08.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP09({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP08 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p09")
        .get();

      let challengesP08Data = [];
      challengesP08.forEach((doc) => {
        challengesP08Data.push(doc.data());
      });

      let reportData = [];

      await asyncForEach(challengesP08Data, async (challengeDoc) => {
        const uid = challengeDoc.uid;
        const companyId = challengeDoc.companyId;
        const docToPush = { APROBADO: challengeDoc.isValid ? "SI" : "NO" };

        try {
          const doc = await db.collection("users").doc(uid).get();
          if (doc.exists) {
            docToPush.CIUDAD = doc.data().city.toUpperCase();
            docToPush.NOMBRE = doc.data().name;
            docToPush.EMAIL = doc.data().email;
          }
        } catch (error) {
          console.error(error);
        }

        try {
          const doc = await db.collection("companies").doc(companyId).get();
          if (doc.exists) {
            docToPush.EMPRESA = doc.data().name;
            docToPush.TRACKSALES = doc.data().cid;
            docToPush.ASESOR = doc.data().salesman;
            docToPush.DEPENDIENTES = doc.data().workers;
          }
        } catch (error) {
          console.error(error);
        }

        reportData.push(docToPush);
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P09");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P09.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP10({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP10 = await db
        .collection("dcm_challenges")
        .where("challengeId", "==", "p10")
        .get();

      let challengesP10Data = [];

      challengesP10.forEach((doc) => {
        challengesP10Data.push(doc.data());
      });

      let usersPromisesArray = [];

      challengesP10Data.forEach((doc) => {
        usersPromisesArray.push(db.collection("users").doc(doc.uid).get());
      });

      const usersPromisesData = await Promise.all(usersPromisesArray);

      let usersData = [];

      usersPromisesData.forEach((doc) => {
        usersData.push(doc.data());
      });

      let companiesPromisesArray = [];

      challengesP10Data.forEach((doc) => {
        companiesPromisesArray.push(
          db.collection("companies").doc(doc.companyId).get()
        );
      });

      const companiesPromisesData = await Promise.all(companiesPromisesArray);

      let companiesData = [];

      companiesPromisesData.forEach((doc) => {
        companiesData.push(doc.data());
      });

      let reportData = [];

      challengesP10Data.forEach((doc, index) => {
        const userData = usersData[index];
        const companyData = companiesData[index];

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P10");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P10.xlsx");
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP11({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP11 = await db
        .collection("cs_challenges")
        .where("challengeId", "==", "p11")
        .get();

      let challengesP11Data = [];

      challengesP11.forEach((doc) => {
        challengesP11Data.push(doc.data());
      });

      let usersPromisesArray = [];

      challengesP11Data.forEach((doc) => {
        usersPromisesArray.push(db.collection("users").doc(doc.uid).get());
      });

      const usersPromisesData = await Promise.all(usersPromisesArray);

      let usersData = [];

      usersPromisesData.forEach((doc) => {
        usersData.push(doc.data());
      });

      let companiesPromisesArray = [];

      challengesP11Data.forEach((doc) => {
        companiesPromisesArray.push(
          db.collection("companies").doc(doc.companyId).get()
        );
      });

      const companiesPromisesData = await Promise.all(companiesPromisesArray);

      let companiesData = [];

      companiesPromisesData.forEach((doc) => {
        companiesData.push(doc.data());
      });

      let reportData = [];

      challengesP11Data.forEach((doc, index) => {
        const userData = usersData[index];
        const companyData = companiesData[index];

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          "PARTE 1": doc.part_1
            ? doc.part_1.isValid
              ? "VALIDO"
              : "NO VALIDO"
            : "NO REALIZADO",
          "PARTE 2": doc.part_2
            ? doc.part_2.isCompleted
              ? doc.part_2.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO COMPLETADO"
            : "NO REALIZADO",
          "PARTE 3": doc.part_3
            ? doc.part_3.isValid
              ? "VALIDO"
              : "NO VALIDO"
            : "NO REALIZADO",
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P11");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P11.xlsx");
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP12({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP12 = await db
        .collection("cs_challenges")
        .where("challengeId", "==", "p12")
        .get();

      let challengesP12Data = [];

      challengesP12.forEach((doc) => {
        challengesP12Data.push(doc.data());
      });

      let usersPromisesArray = [];

      challengesP12Data.forEach((doc) => {
        usersPromisesArray.push(db.collection("users").doc(doc.uid).get());
      });

      const usersPromisesData = await Promise.all(usersPromisesArray);

      let usersData = [];

      usersPromisesData.forEach((doc) => {
        usersData.push(doc.data());
      });

      let companiesPromisesArray = [];

      challengesP12Data.forEach((doc) => {
        companiesPromisesArray.push(
          db.collection("companies").doc(doc.companyId).get()
        );
      });

      const companiesPromisesData = await Promise.all(companiesPromisesArray);

      let companiesData = [];

      companiesPromisesData.forEach((doc) => {
        companiesData.push(doc.data());
      });

      let reportData = [];

      challengesP12Data.forEach((doc, index) => {
        const userData = usersData[index];
        const companyData = companiesData[index];

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          "PARTE 1": doc.part_1
            ? doc.part_1.isValid
              ? "VALIDO"
              : "NO VALIDO"
            : "NO REALIZADO",
          "PARTE 2": doc.part_2
            ? doc.part_2.isValid
              ? "VALIDO"
              : "NO VALIDO"
            : "NO REALIZADO",
          "ESTADO RETO":
            doc.part_1 && doc.part_2
              ? doc.part_1.isValid && doc.part_2.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO COMPLETADO",
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P12");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P12.xlsx");
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP13({}, to) {
    try {
      const XLSX = require("xlsx");

      const challengesP12 = await db
        .collection("cs_challenges")
        .where("challengeId", "==", "p13")
        .get();

      let challengesP13Data = [];

      challengesP12.forEach((doc) => {
        challengesP13Data.push(doc.data());
      });

      let usersPromisesArray = [];

      challengesP13Data.forEach((doc) => {
        usersPromisesArray.push(db.collection("users").doc(doc.uid).get());
      });

      const usersPromisesData = await Promise.all(usersPromisesArray);

      let usersData = [];

      usersPromisesData.forEach((doc) => {
        usersData.push(doc.data());
      });

      let companiesPromisesArray = [];

      challengesP13Data.forEach((doc) => {
        companiesPromisesArray.push(
          db.collection("companies").doc(doc.companyId).get()
        );
      });

      const companiesPromisesData = await Promise.all(companiesPromisesArray);

      let companiesData = [];

      companiesPromisesData.forEach((doc) => {
        companiesData.push(doc.data());
      });

      let reportData = [];

      challengesP13Data.forEach((doc, index) => {
        const userData = usersData[index];
        const companyData = companiesData[index];

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          "PARTE 1": doc.part_1
            ? doc.part_1.isValid
              ? "VALIDO"
              : "NO VALIDO"
            : "NO REALIZADO",
          "PARTE 2": doc.part_2
            ? doc.part_2.isReviewed
              ? doc.part_2.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTA PARTE 2": doc.answer ? doc.answer : "NO RESPONDIDO",
          "ESTADO RETO": doc.isCompleted
            ? doc.isReviewed
              ? doc.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO COMPLETADO",
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P13");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reto_P13.xlsx");
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getNoPartipationReport({}, challengeId) {
    try {
      const XLSX = require("xlsx");

      const allUsersData = [];
      const allUsersFb = await db
        .collection("users")
        .where("role", "==", "WORKER")
        .where("disabled", "==", false)
        .get();
      allUsersFb.forEach((doc) => allUsersData.push(doc.data()));

      const usersByChallengeId = [];
      const usersByChallengeIdFb = await db
        .collection("challenges_2024")
        .where("challengeId", "==", challengeId)
        .get();
      usersByChallengeIdFb.forEach((doc) =>
        usersByChallengeId.push(doc.data().uid)
      );

      const noParticipationUsers = [];
      allUsersData.forEach((user) => {
        if (user.companyName === "OKANA") {
          return;
        }

        if (usersByChallengeId.some((uid) => user.uid == uid)) {
          return;
        }

        noParticipationUsers.push(user);
      });

      const companiesPromisesArray = noParticipationUsers.map((doc) =>
        db.collection("companies").doc(doc.companyId).get()
      );
      const companiesPromisesData = await Promise.all(companiesPromisesArray);
      const companiesData = companiesPromisesData.map((doc) => doc.data());

      const reportData = noParticipationUsers.map((user, index) => {
        const companyData = companiesData[index];

        return {
          CIUDAD: user.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          NIT: companyData.nit,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          CEDULA: user.cc,
          NOMBRE: user.name,
          EMAIL: user.email,
          DIRECCIÓN: user.address,
          "DIRECCIÓN EMPRESA": companyData.address,
        };
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Colaboradores");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(
        workBook,
        `Reporte No Participacion Reto ${challengeId.toUpperCase()}.xlsx`
      );
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getNoPartipationAcademy({}, period) {
    try {
      const XLSX = require("xlsx");

      const allUsersData = [];
      const allUsersFb = await db
        .collection("users")
        .where("role", "==", "WORKER")
        .where("disabled", "==", false)
        .get();
      allUsersFb.forEach((doc) => allUsersData.push(doc.data()));

      const usersByPeriod = [];
      const usersByPeriodFb = await db
        .collection("certifications")
        .where("period", "==", period)
        .where("year", "==", "2024")
        .get();
      usersByPeriodFb.forEach((doc) => usersByPeriod.push(doc.data().uid));

      const noParticipationUsers = [];
      allUsersData.forEach((user) => {
        if (user.companyName === "OKANA") {
          return;
        }

        if (usersByPeriod.some((uid) => user.uid == uid)) {
          return;
        }

        noParticipationUsers.push(user);
      });

      const companiesPromisesArray = noParticipationUsers.map((doc) =>
        db.collection("companies").doc(doc.companyId).get()
      );
      const companiesPromisesData = await Promise.all(companiesPromisesArray);
      const companiesData = companiesPromisesData.map((doc) => doc.data());

      const reportData = noParticipationUsers.map((user, index) => {
        const companyData = companiesData[index];

        return {
          CIUDAD: user.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          NIT: companyData.nit,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          CEDULA: user.cc,
          NOMBRE: user.name,
          EMAIL: user.email,
        };
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Colaboradores");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(
        workBook,
        `Reporte No Participacion Academia Mars ${period.toUpperCase()}.xlsx`
      );
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getTriviasGeneral({}, to) {
    const XLSX = require("xlsx");
    const triviasDocs = await db
      .collectionGroup("trivia")
      .where("answered", "==", true)
      .get();

    const usersDocs = await db.collection("users").get();

    let users = [];
    let trivias = [];
    let usersParticipedP01 = [];
    let usersParticipedP02 = [];
    let usersParticipedP03 = [];
    let usersParticipedP04 = [];
    let usersParticipedP05 = [];
    let usersParticipedP06 = [];
    let usersParticipedP07 = [];
    let usersParticipedP08 = [];
    let usersParticipedP09 = [];
    let usersParticipedP10 = [];
    let usersParticipedP11 = [];
    let usersParticipedP12 = [];
    let usersParticipedP13 = [];

    let colaboratorsParticiped = [];
    usersDocs.forEach((user) => {
      if (user.data().companyName != "OKANA" && !user.data().disabled) {
        users.push(user.data());
      }
    });

    triviasDocs.forEach((trivia) => {
      if (trivia.data().year === "2022") {
        const user = users.filter((user) => {
          return user.uid === trivia.ref.parent.parent.id;
        });

        let name = "";
        let marca = "";

        switch (trivia.data().id) {
          case "art-01":
            name = "Prevención de enfermedad periodontal";
            marca = "Artículo";
            break;
          case "art-02":
            name = "Manejo del cuidado oral de una mascota";
            marca = "Artículo";
            break;
          case "art-03":
            name = "5 tips curiosos que no sabías de tu mascota perruna";
            marca = "Artículo";
            break;
          case "art-04":
            name = "¿Por qué implementar un programa de prevención urinaria?";
            marca = "Artículo";
            break;
          case "art-05":
            name = "Alimentos prohibidos para perros y gatos";
            marca = "Artículo";
            break;
          case "art-06":
            name = "Conceptos básicos de nutrición para perros y gatos";
            marca = "Artículo";
            break;
          case "art-07":
            name = "Macro y micronutrientes para perros y gatos";
            marca = "Artículo";
            break;
          case "art-08":
            name = "Mix feeding";
            marca = "Artículo";
            break;

          case "den-01":
            name = "Dentastix: Beneficios por etapas";
            marca = "Dentastix";
            break;
          case "den-02":
            name = "Dentastix: Guía de alimentación";
            marca = "Dentastix";
            break;
          case "den-03":
            name = "Dentastix: Composición nutricional";
            marca = "Dentastix";
            break;

          case "kit-01":
            name = "Kitekat: Beneficios por etapas";
            marca = "Kitekat";
            break;
          case "kit-02":
            name = "Kitekat: Guía de alimentación";
            marca = "Kitekat";
            break;
          case "kit-03":
            name = "Kitekat: Composición nutricional";
            marca = "Kitekat";
            break;

          case "opt-01":
            name = "Perros - Optimum Cachorro: Beneficios por etapas";
            marca = "Optimum";
            break;
          case "opt-02":
            name = "Perros - Optimum Cachorro: Guía de alimentación";
            marca = "Optimum";
            break;
          case "opt-03":
            name = "Perros - Optimum Cachorro: Composición nutricional";
            marca = "Optimum";
            break;
          case "opt-04":
            name = "Perros - Optimum Razas pequeñas: Beneficios por etapas";
            marca = "Optimum";
            break;
          case "opt-05":
            name = "Perros - Optimum Razas pequeñas: Guía de alimentación";
            marca = "Optimum";
            break;
          case "opt-06":
            name = "Perros - Optimum Razas pequeñas: Composición nutricional";
            marca = "Optimum";
            break;
          case "opt-07":
            name = "Perros - Optimum Adultos: Beneficios por etapas";
            marca = "Optimum";
            break;
          case "opt-08":
            name = "Perros - Optimum Adultos: Guía de alimentación";
            marca = "Optimum";
            break;
          case "opt-09":
            name = "Perros - Optimum Adultos: Composición nutricional";
            marca = "Optimum";
            break;

          case "opt-10":
            name = "Gatos - Optimum Gatitos: Beneficios por etapas";
            marca = "Optimum";
            break;
          case "opt-11":
            name = "Gatos - Optimum Gatitos: Guía de alimentación";
            marca = "Optimum";
            break;
          case "opt-12":
            name = "Gatos - Optimum Gatitos: Composición nutricional";
            marca = "Optimum";
            break;
          case "opt-13":
            name = "Gatos - Optimum Adultos: Beneficios por etapas";
            marca = "Optimum";
            break;
          case "opt-14":
            name = "Gatos - Optimum Adultos: Guía de alimentación";
            marca = "Optimum";
            break;
          case "opt-15":
            name = "Gatos - Optimum Adultos: Composición nutricional";
            marca = "Optimum";
            break;
          case "opt-16":
            name = "Gatos - Optimum Castrados: Beneficios por etapas";
            marca = "Optimum";
            break;
          case "opt-17":
            name = "Gatos - Optimum Castrados: Guía de alimentación";
            marca = "Optimum";
            break;
          case "opt-18":
            name = "Gatos - Optimum Castrados: Composición nutricional";
            marca = "Optimum";
            break;

          case "ped-01":
            name = "Pedigree Cachorro: Beneficios por etapas";
            marca = "Pedigree";
            break;
          case "ped-02":
            name = "Pedigree Cachorro: Guía de alimentación";
            marca = "Pedigree";
            break;
          case "ped-03":
            name = "Pedigree Cachorro: Composición nutricional";
            marca = "Pedigree";
            break;
          case "ped-04":
            name = "Pedigree Razas pequeñas: Beneficios por etapas";
            marca = "Pedigree";
            break;
          case "ped-05":
            name = "Pedigree Razas pequeñas: Guía de alimentación";
            marca = "Pedigree";
            break;
          case "ped-06":
            name = "Pedigree Razas pequeñas: Composición nutricional";
            marca = "Pedigree";
            break;
          case "ped-07":
            name = "Pedigree Razas grandes y senior: Beneficios por etapas";
            marca = "Pedigree";
            break;
          case "ped-08":
            name = "Pedigree Razas grandes y senior: Guía de alimentación";
            marca = "Pedigree";
            break;
          case "ped-09":
            name = "Pedigree Razas grandes y senior: Composición nutricional";
            marca = "Pedigree";
            break;

          case "she-01":
            name = "Sheba Gatitos: Beneficios por etapas";
            marca = "Sheba";
            break;
          case "she-02":
            name = "Sheba Gatitos: Guía de alimentación";
            marca = "Sheba";
            break;
          case "she-03":
            name = "Sheba Gatitos: Composición nutricional";
            marca = "Sheba";
            break;
          case "she-04":
            name = "Sheba Gatos: Beneficios por etapas";
            marca = "Sheba";
            break;
          case "she-05":
            name = "Sheba Gatos: Guía de alimentación";
            marca = "Sheba";
            break;
          case "she-06":
            name = "Sheba Gatos: Composición nutricional";
            marca = "Sheba";
            break;

          case "whi-01":
            name = "Whiskas Gatitos: Beneficios por etapas";
            marca = "Whiskas";
            break;
          case "whi-02":
            name = "Whiskas Gatitos: Guía de alimentación";
            marca = "Whiskas";
            break;
          case "whi-03":
            name = "Whiskas Gatitos: Composición nutricional";
            marca = "Whiskas";
            break;
          case "whi-04":
            name = "Whiskas Gatos: Beneficios por etapas";
            marca = "Whiskas";
            break;
          case "whi-05":
            name = "Whiskas Gatos: Guía de alimentación";
            marca = "Whiskas";
            break;
          case "whi-06":
            name = "Whiskas Gatos: Composición nutricional";
            marca = "Whiskas";
            break;
          case "temp-01":
            name = "Temptations: Trivia lanzamiento";
            marca = "Temptations";
            break;

          default:
            break;
        }

        const getPeriod = (timestamp) => {
          const periodsData = [
            { period: "p01", start: 1641099600000, end: 1643518800000 },
            { period: "p02", start: 1643518800000, end: 1645938000000 },
            { period: "p03", start: 1645938000000, end: 1648357200000 },
            { period: "p04", start: 1648357200000, end: 1650776400000 },
            { period: "p05", start: 1650776400000, end: 1653195600000 },
            { period: "p06", start: 1653195600000, end: 1655614800000 },
            { period: "p07", start: 1655614800000, end: 1658034000000 },
            { period: "p08", start: 1658034000000, end: 1660453200000 },
            { period: "p09", start: 1660453200000, end: 1662872400000 },
            { period: "p10", start: 1662872400000, end: 1665291600000 },
            { period: "p11", start: 1665291600000, end: 1667710800000 },
            { period: "p12", start: 1667710800000, end: 1670130000000 },
            { period: "p13", start: 1670130000000, end: 1672549200000 },
          ];

          return periodsData.reduce((period, periodEvaluated) => {
            if (
              timestamp >= periodEvaluated.start &&
              timestamp < periodEvaluated.end
            ) {
              return periodEvaluated.period;
            } else {
              return period;
            }
          }, "p01");
        };

        const obj = {
          PERIODO: getPeriod(trivia.data().answeredAt),
          CIUDAD: user[0] ? user[0].city.toUpperCase() : "No registra",
          MARCA: marca,
          NOMBRE_TRIVIA: name,
          ESTADO: trivia.data().isCorrect ? "Aprobado" : "Rechazado",
        };

        trivias.push(obj);

        let newElementTriviaPeriod = false;

        if (
          !usersParticipedP01.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p01"
        ) {
          usersParticipedP01.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP02.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p02"
        ) {
          usersParticipedP02.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }
        if (
          !usersParticipedP03.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p03"
        ) {
          usersParticipedP03.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP04.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p04"
        ) {
          usersParticipedP04.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP05.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p05"
        ) {
          usersParticipedP05.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP06.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p06"
        ) {
          usersParticipedP06.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP07.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p07"
        ) {
          usersParticipedP07.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP08.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p08"
        ) {
          usersParticipedP08.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP09.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p09"
        ) {
          usersParticipedP09.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP10.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p10"
        ) {
          usersParticipedP10.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP11.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p11"
        ) {
          usersParticipedP11.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP12.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p12"
        ) {
          usersParticipedP12.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (
          !usersParticipedP13.includes(trivia.ref.parent.parent.id) &&
          trivia.data().period === "p13"
        ) {
          usersParticipedP13.push(trivia.ref.parent.parent.id);
          newElementTriviaPeriod = true;
        }

        if (newElementTriviaPeriod) {
          const obj = {
            PERIODO: getPeriod(trivia.data().answeredAt),
            CIUDAD: user[0] ? user[0].city.toUpperCase() : "No registra",
          };
          colaboratorsParticiped.push(obj);
        }
      }
    });
    const workSheet1 = XLSX.utils.json_to_sheet(trivias);
    const workSheet2 = XLSX.utils.json_to_sheet(colaboratorsParticiped);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet1, "INFO");
    XLSX.utils.book_append_sheet(workBook, workSheet2, "USUARIOS");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "TRIVIAS.xlsx");
  },

  async getRedemptionsByDate({}, to) {
    const XLSX = require("xlsx");
    const redemptions = (
      await db
        .collection("redemptions")
        .where("redemptionDate", ">=", to.myEpochInit)
        .where("redemptionDate", "<=", to.myEpochFinal)
        .get()
    ).docs.map((doc) => doc.data());

    let users = [];
    (
      await Promise.all(
        Array.from(
          new Set(redemptions.map((redemption) => redemption.uid))
        ).map((uid) => db.collection("users").doc(uid).get())
      )
    ).forEach((doc) => {
      if (doc.exists) {
        users.push(doc.data());
      }
    });

    let companies = [];
    (
      await Promise.all(
        Array.from(new Set(users.map((user) => user.companyId))).map((cid) =>
          db.collection("companies").doc(cid).get()
        )
      )
    ).forEach((doc) => {
      if (doc.exists) {
        companies.push(doc.data());
      }
    });

    const finalArray = redemptions.map((redemption) => {
      const user = users.find((user) => user.uid === redemption.uid);
      const company = companies.find((c) => c.cid === user?.companyId);
      const myDate = new Date(redemption.redemptionDate);
      if (user)
        return {
          FECHA: myDate.toLocaleDateString(),
          CIUDAD: user.city.toUpperCase(),
          REGION: company?.region ?? "EMPRESA ELIMINADA",
          NIT: company?.nit ?? "EMPRESA ELIMINADA",
          TRACKSALES: company?.cid ?? "EMPRESA ELIMINADA",
          EMPRESA: company?.name ?? "EMPRESA ELIMINADA",
          CEDULA: user.cc,
          NOMBRE: user.name,
          EMAIL: user.email,
          CELULAR: user.phone,
          DIRECCION: user.address,
          PREMIO: redemption.awardName,
        };
    });

    const workSheet = XLSX.utils.json_to_sheet(finalArray);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "INFO");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "REPORTE REDENCIONES.xlsx");
  },

  async getPointsCompliance() {
    const XLSX = require("xlsx");

    const points = await db
      .collection("points")
      .where("category", "==", "Cierre de bimestre")
      .where("year", "==", "2024")
      .get();
    let pointsDocs = [];
    points.forEach((doc) => pointsDocs.push(doc.data()));

    const users = pointsDocs.filter((doc, index) => {
      return pointsDocs.findIndex((p) => p.uid == doc.uid) == index;
    });

    const usersPromises = users.map((doc) =>
      db.collection("users").doc(doc.uid).get()
    );
    let usersData = [];
    const usersResponses = await Promise.all(usersPromises);
    usersResponses.forEach((user) => {
      if (!user.data().disabled) {
        usersData.push(user.data());
      }
    });

    let reportData = [];

    pointsDocs.forEach((doc) => {
      const userData = usersData.find((user) => user.uid == doc.uid);

      if (userData) {
        const obj = {
          NOMBRE: userData.name.toUpperCase(),
          EMAIL: userData.email,
          TRACKSALES: userData.companyId,
          PERIODOS: doc.period,
          BIMESTRE: doc.bimester,
          VIGENCIA: doc.year,
          PUNTOS: doc.points,
        };

        reportData.push(obj);
      }
    });

    const workSheet = XLSX.utils.json_to_sheet(reportData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "USUARIOS");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "REPORTE_PUNTOS_CUMPLIMIENTO.xlsx");
  },

  async getPointsComplianceCompanies() {
    const XLSX = require("xlsx");

    const compliance2022Fb = await db
      .collection("compliance")
      .where("year", "==", "2024")
      .get();
    let compliances = [];
    let eliminatedCompanies = [];

    compliance2022Fb.forEach((compliance) => {
      compliances.push(compliance.data());
    });

    const companies = compliances.filter((compliance, index) => {
      return (
        compliances.findIndex((c) => c.companyId == compliance.companyId) ==
        index
      );
    });

    const companiesPromises = companies.map((company) =>
      db.collection("companies").doc(company.companyId).get()
    );
    const companiesResponses = await Promise.all(companiesPromises);
    let companiesData = [];
    companiesResponses.forEach((doc) => {
      if (doc.exists) {
        companiesData.push(doc.data());
      } else {
        eliminatedCompanies.push(doc.id);
        console.log(doc.id, "No existe");
      }
    });

    const goalsPromises = companiesData.map((company) =>
      db
        .collection("companies")
        .doc(company.cid)
        .collection("newGoals")
        .doc("2024")
        .get()
    );
    const goalsResponses = await Promise.all(goalsPromises);
    goalsResponses.forEach((doc, index) => {
      if (doc.exists) {
        companiesData[index].goals = doc.data();
      }
    });

    let reportData = [];
    compliances.forEach((compliance) => {
      if (!eliminatedCompanies.includes(compliance.companyId)) {
        const companyData = companiesData.find(
          (company) => company.cid == compliance.companyId
        );
        const obj = {
          CIUDAD: companyData?.city?.toUpperCase() || "SIN CIUDAD",
          REGION: companyData?.region?.toUpperCase() || "SIN REGIÓN",
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          META: companyData.goals
            ? companyData.goals[compliance.bimester].total
            : "SIN META",
          META_WET: companyData.goals
            ? companyData.goals[compliance.bimester].wet
            : "SIN META",
          META_SNACK: companyData.goals
            ? companyData.goals[compliance.bimester].snack
            : "SIN META",
          META_DRY: companyData.goals
            ? companyData.goals[compliance.bimester].dry
            : "SIN META",
          COMPRAS: compliance.totalAmount,
          COMPRAS_WET: compliance.amountWet,
          COMPRAS_SNACK: compliance.amountSnack,
          COMPRAS_DRY: compliance.amountDry,
          PORCENTAJE: compliance.percentage,
          PORCENTAJE_WET: compliance.percentageWet,
          PORCENTAJE_SNACK: compliance.percentageSnack,
          PORCENTAJE_DRY: compliance.percentageDry,
          COLABORADORES: companyData.workers,
          PUNTOS_EMPRESA: compliance.points * companyData.workers,
          PUNTOS_COLABORADOR: compliance.points,
          PUNTOS_WET: compliance.pointsWet,
          PUNTOS_SNACK: compliance.pointsSnack,
          PUNTOS_DRY: compliance.pointsDry,
          BIMESTRE: compliance.bimester,
          PERIODOS: compliance.periods,
          VIGENCIA: compliance.year,
        };

        reportData.push(obj);
      }
    });

    const workSheet = XLSX.utils.json_to_sheet(reportData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "EMPRESAS");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "REPORTE PUNTOS CUMPLIMIENTO EMPRESAS.xlsx");
  },

  async getPointsTemptationsCampaign() {
    const XLSX = require("xlsx");

    let categoryPointsData = [];

    const categoryPointsSnap = await db
      .collection("points")
      .where("category", "==", "Foto campaña Temptations")
      .get();
    categoryPointsSnap.forEach((doc) => {
      categoryPointsData.push(doc.data());
    });

    let promisesArray = [];

    categoryPointsData.forEach((doc) => {
      promisesArray.push(db.collection("users").doc(doc.uid).get());
    });

    const promisesData = await Promise.all(promisesArray);

    let usersData = [];

    promisesData.forEach((doc) => {
      usersData.push(doc.data());
    });

    let reportData = [];

    categoryPointsData.forEach((doc) => {
      const userData = usersData.find((user) => user.uid === doc.uid);

      reportData.push({
        PERIODO: doc.period,
        PUNTOS: doc.points,
        CIUDAD: userData.city.toUpperCase(),
        EMPRESA: userData.companyName,
        TRACKSALES: userData.companyId,
        NOMBRE: userData.name,
        EMAIL: userData.email,
      });
    });

    const workSheet = XLSX.utils.json_to_sheet(reportData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "PUNTOS");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "REPORTE_CAMPAÑA_BANNER_TEMPTATIONS.xlsx");
  },

  async getPointsReport() {
    const XLSX = require("xlsx");

    try {
      let reportData = [];
      const querySnapshot = await db
        .collection("users")
        .where("companyName", "!=", "OKANA")
        .get();

      querySnapshot.forEach((doc) => {
        reportData.push({
          TRACKSALES: doc.data().companyId,
          CEDULA: doc.data().cc,
          NOMBRE: doc.data().name,
          CORREO: doc.data().email,
          CIUDAD: doc.data().city.toUpperCase(),
          PUNTOS_ASIGNADOS: doc.data().totalPoints,
          PUNTOS_REDIMIDOS: doc.data().totalPoints - doc.data().points,
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "USUARIOS");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE_PUNTOS.xlsx");
    } catch (error) {
      console.error(error);
    }
  },

  async getPoints2024Report() {
    const XLSX = require("xlsx");

    try {
      const points2024 = (
        await db
          .collection("points")
          .where("timeStamp", ">=", 1704085200000)
          .get()
      ).docs.map((doc) => doc.data());
      const redemptions2024 = (
        await db
          .collection("redemptions")
          .where("redemptionDate", ">=", 1704085200000)
          .get()
      ).docs.map((doc) => doc.data());

      const usersFull = [...points2024, ...redemptions2024];
      const usersList = Array.from(new Set(usersFull.map((doc) => doc.uid)));
      let users = [];
      (
        await Promise.all(
          usersList.map((uid) => db.collection("users").doc(uid).get())
        )
      ).forEach((doc) => {
        if (doc.exists) users.push(doc.data());
      });

      const companiesList = Array.from(
        new Set(users.map((doc) => doc.companyId))
      );
      let companies = [];
      (
        await Promise.all(
          companiesList.map((companyId) =>
            db.collection("companies").doc(companyId).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) companies.push(doc.data());
      });

      const reportData = companies.map((company) => {
        const companyUsers = users
          .filter((user) => user.companyId === company.cid)
          .map((user) => user.uid);
        const challengesPoints = points2024
          .filter(
            (doc) => companyUsers.includes(doc.uid) && doc.category === "Retos"
          )
          .reduce((acc, doc) => acc + doc.points, 0);
        const academyPoints = points2024
          .filter(
            (doc) =>
              companyUsers.includes(doc.uid) && doc.category === "Mars Academy"
          )
          .reduce((acc, doc) => acc + doc.points, 0);
        const selloutPoints = points2024
          .filter(
            (doc) =>
              companyUsers.includes(doc.uid) &&
              (doc.category === "Cierre de bimestre" ||
                doc.category === "Cierre de periodo")
          )
          .reduce((acc, doc) => acc + doc.points, 0);
        const redemptions = redemptions2024
          .filter((doc) => doc.companyId === company.cid)
          .reduce((acc, doc) => acc + doc.awardPoints, 0);

        return {
          TRACKSALES: company.cid,
          NIT: company.nit,
          EMPRESA: company.name,
          REGION: company.region,
          CIUDAD: company.city.toUpperCase(),
          "PUNTOS ASIGNADOS RETOS": challengesPoints,
          "PUNTOS ASIGNADOS ACADEMIA": academyPoints,
          "PUNTOS ASIGNADOS SELLOUT": selloutPoints,
          "PUNTOS REDIMIDOS": redemptions,
        };
      });
      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "EMPRESAS");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE PUNTOS 2024.xlsx");
    } catch (error) {
      console.error(error);
    }
  },

  async getPointsP10Report() {
    const XLSX = require("xlsx");

    try {
      let manualPointsData = [];

      const manualPointsSnapshot = await db
        .collection("points")
        .where("category", "==", "Reto P10 manual")
        .get();

      manualPointsSnapshot.forEach((doc) => {
        manualPointsData.push(doc.data());
      });

      let promisesArray = [];

      manualPointsData.forEach((doc) => {
        promisesArray.push(db.collection("users").doc(doc.uid).get());
      });

      const promisesData = await Promise.all(promisesArray);

      let usersData = [];

      promisesData.forEach((doc) => {
        usersData.push(doc.data());
      });

      let reportData = [];

      manualPointsData.forEach((doc) => {
        const userData = usersData.find((user) => user.uid === doc.uid);

        reportData.push({
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          NOMBRE: userData.name,
          EMAIL: userData.email,
          PUNTOS_ASIGNADOS: doc.points,
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "USUARIOS");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE_30_PUNTOS_P10.xlsx");
    } catch (error) {
      console.error(error);
    }
  },

  async getCsRedemtionsReport() {
    try {
      const XLSX = require("xlsx");

      const cdRedemtions = await db.collection("cs_redemptions").get();

      let csRedemtionsData = [];

      cdRedemtions.forEach((doc) => {
        csRedemtionsData.push(doc.data());
      });

      const usersCsRedemtionsArray = csRedemtionsData.map((doc) =>
        db.collection("users").doc(doc.uid).get()
      );

      const usersPromisesData = await Promise.all(usersCsRedemtionsArray);

      let usersData = [];

      usersPromisesData.forEach((doc) => {
        usersData.push(doc.data());
      });

      const reportData = csRedemtionsData.map((doc, index) => {
        const userData = usersData[index];
        const csRedemtion = csRedemtionsData[index];

        return {
          FECHA: new Date(csRedemtion.redemptionDate).toLocaleDateString(),
          CIUDAD: userData.city.toUpperCase(),
          TRACKSALES: userData.companyId,
          EMPRESA: userData.companyName,
          CEDULA: userData.cc,
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CELULAR: userData.phone,
          DIRECCION: userData.address,
          PREMIO: csRedemtion.awardName,
        };
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workBook,
        workSheet,
        "Redenciones estrella navideña"
      );
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Redenciones_estrella_navideña.xlsx");
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getChallengeP02_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesP02 = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p02")
        .get();

      let challengesP02Data = [];
      challengesP02.forEach((doc) => {
        challengesP02Data.push(doc.data());
      });

      let usersPromise = [];
      challengesP02.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        usersData.push(doc.data());
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        companiesData.push(doc.data());
      });

      let reportData = [];
      challengesP02Data.forEach((challenge, index) => {
        const userData = usersData[index];
        const companyData = companiesData[index];

        if (!userData.disabled && companyData) {
          reportData.push({
            NOMBRE: userData.name,
            EMAIL: userData.email,
            CIUDAD: userData.city.toUpperCase(),
            EMPRESA: companyData.name,
            TRACKSALES: companyData.cid,
            ASESOR: companyData.salesman,
            DEPENDIENTES: companyData.workers,
            "PARTE 1": challenge.part_1
              ? challenge.part_1.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REALIZADO",
            "PARTE 2": challenge.part_2
              ? challenge.part_2.isReviewed
                ? challenge.part_2.isValid
                  ? "VALIDO"
                  : "NO VALIDO"
                : "NO REVISADO"
              : "NO REALIZADO",
            "ESTADO RETO": challenge.isValid ? "VALIDO" : "NO VALIDO",
            "RESPUESTAS CORRECTAS": challenge.part_2
              ? challenge.part_2.correctAnswers
              : "NO RESPONDIDO",
            "RESPUESTA 1": challenge.part_2
              ? challenge.answers[0].isValid
                ? "CORRECTA"
                : "INCORRECTA"
              : "NO RESPONDIDO",
            "RESPUESTA 2": challenge.part_2
              ? challenge.answers[1].isValid
                ? "CORRECTA"
                : "INCORRECTA"
              : "NO RESPONDIDO",
            "RESPUESTA 3": challenge.part_2
              ? challenge.answers[2].isValid
                ? "CORRECTA"
                : "INCORRECTA"
              : "NO RESPONDIDO",
            "RESPUESTA 4": challenge.part_2
              ? challenge.answers[3].isValid
                ? "CORRECTA"
                : "INCORRECTA"
              : "NO RESPONDIDO",
            "RESPUESTA 5": challenge.part_2
              ? challenge.answers[4].isValid
                ? "CORRECTA"
                : "INCORRECTA"
              : "NO RESPONDIDO",
            "RESPUESTA 6": challenge.part_2
              ? challenge.answers[5].isValid
                ? "CORRECTA"
                : "INCORRECTA"
              : "NO RESPONDIDO",
            "RESPUESTA 7": challenge.part_2
              ? challenge.answers[6].isValid
                ? "CORRECTA"
                : "INCORRECTA"
              : "NO RESPONDIDO",
          });
        }
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P02");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "RETO_P02_2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP03_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesP03 = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p03")
        .get();

      let challengesP03Data = [];
      challengesP03.forEach((doc) => {
        challengesP03Data.push(doc.data());
      });

      let usersPromise = [];
      challengesP03.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesP03Data.forEach((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge.part_1
            ? challenge.part_1.isValid
              ? "VALIDO"
              : "NO VALIDO"
            : "NO REALIZADO",
          "PARTE 2": challenge.part_2
            ? challenge.part_2.isReviewed
              ? challenge.part_2.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "ESTADO RETO": challenge.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": challenge.part_2
            ? challenge.part_2.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge.part_2
            ? challenge.answers[0].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge.part_2
            ? challenge.answers[1].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge.part_2
            ? challenge.answers[2].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge.part_2
            ? challenge.answers[3].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": challenge.part_2
            ? challenge.answers[4].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 6": challenge.part_2
            ? challenge.answers[5].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 7": challenge.part_2
            ? challenge.answers[6].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P03");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "RETO_P03_2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP04_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challenges = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p04")
        .get();

      let challengesData = [];
      challenges?.forEach((doc) => {
        challengesData.push(doc.data());
      });

      let usersPromise = [];
      challenges?.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData?.forEach((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          DIRECCIÓN: userData.address ?? "",
          CELULAR: userData.phone ?? "",
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P04");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "RETO_P04_2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP05_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challenges = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p05")
        .get();

      let challengesData = [];
      challenges?.forEach((doc) => {
        challengesData.push(doc.data());
      });

      let usersPromise = [];
      challenges?.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData?.forEach((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P05");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "RETO_P05_2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP06_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challenges = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p06")
        .get();

      let challengesData = [];
      challenges?.forEach((doc) => {
        challengesData.push(doc.data());
      });

      let usersPromise = [];
      challenges?.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData?.forEach((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CELULAR: userData.phone,
          CIUDAD: userData.city.toUpperCase(),
          DIRECCIÓN: userData.address,
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": challenge?.part_2
            ? challenge?.part_2_answers[4]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 6": challenge?.part_2
            ? challenge?.part_2_answers[5]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P06");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "RETO_P06_2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP07_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challenges = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p07")
        .get();

      let challengesData = [];
      challenges?.forEach((doc) => {
        challengesData.push(doc.data());
      });

      let usersPromise = [];
      challenges?.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData?.forEach((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": challenge?.part_2
            ? challenge?.part_2_answers[4]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P07");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P07 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP08_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challenges = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p08")
        .get();

      let challengesData = [];
      challenges?.forEach((doc) => {
        challengesData.push(doc.data());
      });

      let usersPromise = [];
      challenges?.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData?.forEach((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P08");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P08 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP09_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challenges = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p09")
        .get();

      let challengesData = [];
      challenges?.forEach((doc) => {
        challengesData.push(doc.data());
      });

      let usersPromise = [];
      challenges?.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData?.forEach((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P09 P10");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P09 P10 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP11_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challenges = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p11")
        .get();

      let challengesData = [];
      challenges?.forEach((doc) => {
        challengesData.push(doc.data());
      });

      let usersPromise = [];
      challenges?.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData?.forEach((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": challenge?.part_2
            ? challenge?.part_2_answers[4]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P11");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P11 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP12_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challenges = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p12")
        .get();

      let challengesData = [];
      challenges?.forEach((doc) => {
        challengesData.push(doc.data());
      });

      let usersPromise = [];
      challenges?.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData?.forEach((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": challenge?.part_2
            ? challenge?.part_2_answers[4]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P12");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P12 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP13_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const challenges = await db
        .collection("challenges_2023")
        .where("challengeId", "==", "p13")
        .get();

      let challengesData = [];
      challenges?.forEach((doc) => {
        challengesData.push(doc.data());
      });

      let usersPromise = [];
      challenges?.forEach((challenge) => {
        usersPromise.push(
          db.collection("users").doc(challenge.data().uid).get()
        );
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companyPromise = [];
      usersData.forEach((user) => {
        companyPromise.push(
          db.collection("companies").doc(user.companyId).get()
        );
      });
      DataPromise = await Promise.all(companyPromise);
      let companiesData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData?.forEach((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          TRACKSALES: companyData.cid,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": challenge?.part_2
            ? challenge?.part_2_answers[4]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Reto P13");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P13 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getChallengeP02_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesData = (
        await db
          .collection("challenges_2024")
          .where("challengeId", "==", "p02")
          .get()
      ).docs.map((doc) => doc.data());

      const users = await Promise.all(
        challengesData.map((challenge) =>
          db.collection("users").doc(challenge.uid).get()
        )
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      const companies = await Promise.all(
        Array.from(new Set(usersData.map((user) => user.companyId))).map(
          (company) => db.collection("companies").doc(company).get()
        )
      );
      let companiesData = [];
      companies.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      const points = (
        await db
          .collection("points")
          .where(
            "ref",
            "==",
            "Puntos extras por cumplimiento del reto periodo 2 y 3 2024"
          )
          .get()
      ).docs.map((doc) => doc.data());

      let reportData = [];
      challengesData.map((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        const pointsData = points.find((p) => p.uid == challenge.uid);
        const totalPoints = pointsData ? pointsData.points : 0;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          REGION: companyData.region,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          PUNTOS: totalPoints,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": challenge?.part_2
            ? challenge?.part_2_answers[4]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const companiesReportData = companiesData.map((company) => {
        const companyUsers = usersData.filter(
          (user) => user.companyId === company.cid
        );
        const challengePoints = companyUsers.reduce((acc, user) => {
          const pointsData = points.find((p) => p.uid == user.uid);
          const totalPoints = pointsData ? pointsData.points : 0;
          return acc + totalPoints;
        }, 0);

        return {
          NOMBRE: company.name,
          CIUDAD: company.city.toUpperCase(),
          REGION: company.region,
          NIT: company.nit,
          TRACKSALES: company.cid,
          ASESOR: company.salesman,
          DEPENDIENTES: company.workers,
          "PUNTOS GANADOS": challengePoints,
        };
      });

      const workSheet1 = XLSX.utils.json_to_sheet(reportData);
      const workSheet2 = XLSX.utils.json_to_sheet(companiesReportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet1, "Usuarios");
      XLSX.utils.book_append_sheet(workBook, workSheet2, "Empresas");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P02 P03 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP04_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesData = (
        await db
          .collection("challenges_2024")
          .where("challengeId", "==", "p04")
          .get()
      ).docs.map((doc) => doc.data());

      if (challengesData.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        challengesData.map((challenge) =>
          db.collection("users").doc(challenge.uid).get()
        )
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      const companies = await Promise.all(
        Array.from(new Set(usersData.map((user) => user.companyId))).map(
          (company) => db.collection("companies").doc(company).get()
        )
      );
      let companiesData = [];
      companies.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      const points = (
        await db
          .collection("points")
          .where(
            "ref",
            "==",
            "Puntos extras por cumplimiento del reto periodo 4 2024"
          )
          .get()
      ).docs.map((doc) => doc.data());

      let reportData = [];
      challengesData.map((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        const pointsData = points.find((p) => p.uid == challenge.uid);
        const totalPoints = pointsData ? pointsData.points : 0;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          REGION: companyData.region,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          PUNTOS: totalPoints,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": challenge?.part_2
            ? challenge?.part_2_answers[4]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const companiesReportData = companiesData.map((company) => {
        const companyUsers = usersData.filter(
          (user) => user.companyId === company.cid
        );
        const challengePoints = companyUsers.reduce((acc, user) => {
          const pointsData = points.find((p) => p.uid == user.uid);
          const totalPoints = pointsData ? pointsData.points : 0;
          return acc + totalPoints;
        }, 0);

        return {
          NOMBRE: company.name,
          CIUDAD: company.city.toUpperCase(),
          REGION: company.region,
          NIT: company.nit,
          TRACKSALES: company.cid,
          ASESOR: company.salesman,
          DEPENDIENTES: company.workers,
          "PUNTOS GANADOS": challengePoints,
        };
      });

      const workSheet1 = XLSX.utils.json_to_sheet(reportData);
      const workSheet2 = XLSX.utils.json_to_sheet(companiesReportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet1, "Usuarios");
      XLSX.utils.book_append_sheet(workBook, workSheet2, "Empresas");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P04 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP05_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesData = (
        await db
          .collection("challenges_2024")
          .where("challengeId", "==", "p05")
          .get()
      ).docs.map((doc) => doc.data());

      if (challengesData.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        challengesData.map((challenge) =>
          db.collection("users").doc(challenge.uid).get()
        )
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      const companies = await Promise.all(
        Array.from(new Set(usersData.map((user) => user.companyId))).map(
          (company) => db.collection("companies").doc(company).get()
        )
      );
      let companiesData = [];
      companies.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      const points = (
        await db
          .collection("points")
          .where(
            "ref",
            "==",
            "Puntos extras por cumplimiento del reto periodo 5 2024"
          )
          .get()
      ).docs.map((doc) => doc.data());

      let reportData = [];
      challengesData.map((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        const pointsData = points.find((p) => p.uid == challenge.uid);
        const totalPoints = pointsData ? pointsData.points : 0;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          REGION: companyData.region,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          PUNTOS: totalPoints,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": challenge?.part_2
            ? challenge?.part_2_answers[4]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const companiesReportData = companiesData.map((company) => {
        const companyUsers = usersData.filter(
          (user) => user.companyId === company.cid
        );
        const challengePoints = companyUsers.reduce((acc, user) => {
          const pointsData = points.find((p) => p.uid == user.uid);
          const totalPoints = pointsData ? pointsData.points : 0;
          return acc + totalPoints;
        }, 0);

        return {
          NOMBRE: company.name,
          CIUDAD: company.city.toUpperCase(),
          REGION: company.region,
          NIT: company.nit,
          TRACKSALES: company.cid,
          ASESOR: company.salesman,
          DEPENDIENTES: company.workers,
          "PUNTOS GANADOS": challengePoints,
        };
      });

      const workSheet1 = XLSX.utils.json_to_sheet(reportData);
      const workSheet2 = XLSX.utils.json_to_sheet(companiesReportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet1, "Usuarios");
      XLSX.utils.book_append_sheet(workBook, workSheet2, "Empresas");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P05 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP06_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesData = (
        await db
          .collection("challenges_2024")
          .where("challengeId", "==", "p06")
          .get()
      ).docs.map((doc) => doc.data());

      if (challengesData.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        challengesData.map((challenge) =>
          db.collection("users").doc(challenge.uid).get()
        )
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      const companies = await Promise.all(
        Array.from(new Set(usersData.map((user) => user.companyId))).map(
          (company) => db.collection("companies").doc(company).get()
        )
      );
      let companiesData = [];
      companies.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      const points = (
        await db
          .collection("points")
          .where(
            "ref",
            "==",
            "Puntos extras por cumplimiento del reto periodo 6 2024"
          )
          .get()
      ).docs.map((doc) => doc.data());

      let reportData = [];
      challengesData.map((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        const pointsData = points.find((p) => p.uid == challenge.uid);
        const totalPoints = pointsData ? pointsData.points : 0;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          REGION: companyData.region,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          PUNTOS: totalPoints,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": challenge?.part_2
            ? challenge?.part_2_answers[0]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": challenge?.part_2
            ? challenge?.part_2_answers[1]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": challenge?.part_2
            ? challenge?.part_2_answers[2]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": challenge?.part_2
            ? challenge?.part_2_answers[3]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": challenge?.part_2
            ? challenge?.part_2_answers[4]?.isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const companiesReportData = companiesData.map((company) => {
        const companyUsers = usersData.filter(
          (user) => user.companyId === company.cid
        );
        const challengePoints = companyUsers.reduce((acc, user) => {
          const pointsData = points.find((p) => p.uid == user.uid);
          const totalPoints = pointsData ? pointsData.points : 0;
          return acc + totalPoints;
        }, 0);

        return {
          NOMBRE: company.name,
          CIUDAD: company.city.toUpperCase(),
          REGION: company.region,
          NIT: company.nit,
          TRACKSALES: company.cid,
          ASESOR: company.salesman,
          DEPENDIENTES: company.workers,
          "PUNTOS GANADOS": challengePoints,
        };
      });

      const workSheet1 = XLSX.utils.json_to_sheet(reportData);
      const workSheet2 = XLSX.utils.json_to_sheet(companiesReportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet1, "Usuarios");
      XLSX.utils.book_append_sheet(workBook, workSheet2, "Empresas");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P06 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP07_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesData = (
        await db
          .collection("challenges_2024")
          .where("challengeId", "==", "p07")
          .get()
      ).docs.map((doc) => doc.data());

      if (challengesData.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        challengesData.map((challenge) =>
          db.collection("users").doc(challenge.uid).get()
        )
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      const companies = await Promise.all(
        Array.from(new Set(usersData.map((user) => user.companyId))).map(
          (company) => db.collection("companies").doc(company).get()
        )
      );
      let companiesData = [];
      companies.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      const points = (
        await db
          .collection("points")
          .where(
            "ref",
            "==",
            "Puntos extras por cumplimiento del reto periodo 7 2024"
          )
          .get()
      ).docs.map((doc) => doc.data());

      let reportData = [];
      challengesData.map((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        const pointsData = points.find((p) => p.uid == challenge.uid);
        const totalPoints = pointsData ? pointsData.points : 0;

        let lastPart2;

        if (challenge?.part_2) {
          if (challenge.part_2.attempt == 1) {
            lastPart2 = challenge.part_2_answers;
          } else {
            lastPart2 = challenge[`part_2_answers_${challenge.part_2.attempt}`];
          }
        }

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          REGION: companyData.region,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          PUNTOS: totalPoints,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": lastPart2
            ? lastPart2[0].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": lastPart2
            ? lastPart2[1].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": lastPart2
            ? lastPart2[2].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": lastPart2
            ? lastPart2[3].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": lastPart2
            ? lastPart2[4].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 6": lastPart2
            ? lastPart2[5].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const companiesReportData = companiesData.map((company) => {
        const companyUsers = usersData.filter(
          (user) => user.companyId === company.cid
        );
        const challengePoints = companyUsers.reduce((acc, user) => {
          const pointsData = points.find((p) => p.uid == user.uid);
          const totalPoints = pointsData ? pointsData.points : 0;
          return acc + totalPoints;
        }, 0);

        return {
          NOMBRE: company.name,
          CIUDAD: company.city.toUpperCase(),
          REGION: company.region,
          NIT: company.nit,
          TRACKSALES: company.cid,
          ASESOR: company.salesman,
          DEPENDIENTES: company.workers,
          "PUNTOS GANADOS": challengePoints,
        };
      });

      const workSheet1 = XLSX.utils.json_to_sheet(reportData);
      const workSheet2 = XLSX.utils.json_to_sheet(companiesReportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet1, "Usuarios");
      XLSX.utils.book_append_sheet(workBook, workSheet2, "Empresas");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P07 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP08_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesData = (
        await db
          .collection("challenges_2024")
          .where("challengeId", "==", "p08")
          .get()
      ).docs.map((doc) => doc.data());

      if (challengesData.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        challengesData.map((challenge) =>
          db.collection("users").doc(challenge.uid).get()
        )
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      const companies = await Promise.all(
        Array.from(new Set(usersData.map((user) => user.companyId))).map(
          (company) => db.collection("companies").doc(company).get()
        )
      );
      let companiesData = [];
      companies.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      const points = (
        await db
          .collection("points")
          .where(
            "ref",
            "==",
            "Puntos extras por cumplimiento del reto periodo 8 2024"
          )
          .get()
      ).docs.map((doc) => doc.data());

      let reportData = [];
      challengesData.map((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        const pointsData = points.find((p) => p.uid == challenge.uid);
        const totalPoints = pointsData ? pointsData.points : 0;

        let lastPart2;

        if (challenge?.part_2) {
          if (challenge.part_2.attempt == 1) {
            lastPart2 = challenge.part_2_answers;
          } else {
            lastPart2 = challenge[`part_2_answers_${challenge.part_2.attempt}`];
          }
        }

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          REGION: companyData.region,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          PUNTOS: totalPoints,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": lastPart2
            ? lastPart2[0].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": lastPart2
            ? lastPart2[1].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": lastPart2
            ? lastPart2[2].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const companiesReportData = companiesData.map((company) => {
        const companyUsers = usersData.filter(
          (user) => user.companyId === company.cid
        );
        const challengePoints = companyUsers.reduce((acc, user) => {
          const pointsData = points.find((p) => p.uid == user.uid);
          const totalPoints = pointsData ? pointsData.points : 0;
          return acc + totalPoints;
        }, 0);

        return {
          NOMBRE: company.name,
          CIUDAD: company.city.toUpperCase(),
          REGION: company.region,
          NIT: company.nit,
          TRACKSALES: company.cid,
          ASESOR: company.salesman,
          DEPENDIENTES: company.workers,
          "PUNTOS GANADOS": challengePoints,
        };
      });

      const workSheet1 = XLSX.utils.json_to_sheet(reportData);
      const workSheet2 = XLSX.utils.json_to_sheet(companiesReportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet1, "Usuarios");
      XLSX.utils.book_append_sheet(workBook, workSheet2, "Empresas");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P08 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP09_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesData = (
        await db
          .collection("challenges_2024")
          .where("challengeId", "==", "p09")
          .get()
      ).docs.map((doc) => doc.data());

      if (challengesData.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        challengesData.map((challenge) =>
          db.collection("users").doc(challenge.uid).get()
        )
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      const companies = await Promise.all(
        Array.from(new Set(usersData.map((user) => user.companyId))).map(
          (company) => db.collection("companies").doc(company).get()
        )
      );
      let companiesData = [];
      companies.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      const points = (
        await db
          .collection("points")
          .where(
            "ref",
            "==",
            "Puntos extras por cumplimiento del reto periodo 9 2024"
          )
          .get()
      ).docs.map((doc) => doc.data());

      let reportData = [];
      challengesData.map((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        const pointsData = points.find((p) => p.uid == challenge.uid);
        const totalPoints = pointsData ? pointsData.points : 0;

        let lastPart2;

        if (challenge?.part_2) {
          if (challenge.part_2.attempt == 1) {
            lastPart2 = challenge.part_2_answers;
          } else {
            lastPart2 = challenge[`part_2_answers_${challenge.part_2.attempt}`];
          }
        }

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          REGION: companyData.region,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          PUNTOS: totalPoints,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": lastPart2
            ? lastPart2[0].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": lastPart2
            ? lastPart2[1].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": lastPart2
            ? lastPart2[2].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": lastPart2
            ? lastPart2[3].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": lastPart2
            ? lastPart2[4].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 6": lastPart2
            ? lastPart2[5].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 7": lastPart2
            ? lastPart2[6].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const companiesReportData = companiesData.map((company) => {
        const companyUsers = usersData.filter(
          (user) => user.companyId === company.cid
        );
        const challengePoints = companyUsers.reduce((acc, user) => {
          const pointsData = points.find((p) => p.uid == user.uid);
          const totalPoints = pointsData ? pointsData.points : 0;
          return acc + totalPoints;
        }, 0);

        return {
          NOMBRE: company.name,
          CIUDAD: company.city.toUpperCase(),
          REGION: company.region,
          NIT: company.nit,
          TRACKSALES: company.cid,
          ASESOR: company.salesman,
          DEPENDIENTES: company.workers,
          "PUNTOS GANADOS": challengePoints,
        };
      });

      const workSheet1 = XLSX.utils.json_to_sheet(reportData);
      const workSheet2 = XLSX.utils.json_to_sheet(companiesReportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet1, "Usuarios");
      XLSX.utils.book_append_sheet(workBook, workSheet2, "Empresas");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P09 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP10_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesData = (
        await db
          .collection("challenges_2024")
          .where("challengeId", "==", "p10")
          .get()
      ).docs.map((doc) => doc.data());

      if (challengesData.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        challengesData.map((challenge) =>
          db.collection("users").doc(challenge.uid).get()
        )
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      const companies = await Promise.all(
        Array.from(new Set(usersData.map((user) => user.companyId))).map(
          (company) => db.collection("companies").doc(company).get()
        )
      );
      let companiesData = [];
      companies.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData.map((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        let lastPart2;

        if (challenge?.part_2) {
          if (challenge.part_2.attempt == 1) {
            lastPart2 = challenge.part_2_answers;
          } else {
            lastPart2 = challenge[`part_2_answers_${challenge.part_2.attempt}`];
          }
        }

        reportData.push({
          FECHA: new Date(challenge.createdAt).toLocaleDateString(),
          PERIODO: challenge.createdAt <= 1728190799000 ? "P10" : "P11",
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          REGION: companyData.region,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 4": challenge?.part_4
            ? challenge?.part_4?.isReviewed
              ? challenge?.part_4?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": lastPart2
            ? lastPart2[0].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": lastPart2
            ? lastPart2[1].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": lastPart2
            ? lastPart2[2].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": lastPart2
            ? lastPart2[3].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": lastPart2
            ? lastPart2[4].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 6": lastPart2
            ? lastPart2[5].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 7": lastPart2
            ? lastPart2[6].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet1 = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet1, "Usuarios");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P10 P11 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP12_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesData = (
        await db
          .collection("challenges_2024")
          .where("challengeId", "==", "p12")
          .get()
      ).docs.map((doc) => doc.data());

      if (challengesData.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        challengesData.map((challenge) =>
          db.collection("users").doc(challenge.uid).get()
        )
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      const companies = await Promise.all(
        Array.from(new Set(usersData.map((user) => user.companyId))).map(
          (company) => db.collection("companies").doc(company).get()
        )
      );
      let companiesData = [];
      companies.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      const points = (
        await db
          .collection("points")
          .where(
            "ref",
            "==",
            "Puntos extras por cumplimiento del reto periodo 12 2024"
          )
          .get()
      ).docs.map((doc) => doc.data());

      let reportData = [];
      challengesData.map((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        const pointsData = points.find((p) => p.uid == challenge.uid);
        const totalPoints = pointsData ? pointsData.points : 0;

        let lastPart2;

        if (challenge?.part_2) {
          if (challenge.part_2.attempt == 1) {
            lastPart2 = challenge.part_2_answers;
          } else {
            lastPart2 = challenge[`part_2_answers_${challenge.part_2.attempt}`];
          }
        }

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          REGION: companyData.region,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          PUNTOS: totalPoints,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": lastPart2
            ? lastPart2[0].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": lastPart2
            ? lastPart2[1].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": lastPart2
            ? lastPart2[2].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": lastPart2
            ? lastPart2[3].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": lastPart2
            ? lastPart2[4].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const companiesReportData = companiesData.map((company) => {
        const companyUsers = usersData.filter(
          (user) => user.companyId === company.cid
        );
        const challengePoints = companyUsers.reduce((acc, user) => {
          const pointsData = points.find((p) => p.uid == user.uid);
          const totalPoints = pointsData ? pointsData.points : 0;
          return acc + totalPoints;
        }, 0);

        return {
          NOMBRE: company.name,
          CIUDAD: company.city.toUpperCase(),
          REGION: company.region,
          NIT: company.nit,
          TRACKSALES: company.cid,
          ASESOR: company.salesman,
          DEPENDIENTES: company.workers,
          "PUNTOS GANADOS": challengePoints,
        };
      });

      const workSheet1 = XLSX.utils.json_to_sheet(reportData);
      const workSheet2 = XLSX.utils.json_to_sheet(companiesReportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet1, "Usuarios");
      XLSX.utils.book_append_sheet(workBook, workSheet2, "Empresas");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P12 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getChallengeP13_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const challengesData = (
        await db
          .collection("challenges_2024")
          .where("challengeId", "==", "p13")
          .get()
      ).docs.map((doc) => doc.data());

      if (challengesData.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        challengesData.map((challenge) =>
          db.collection("users").doc(challenge.uid).get()
        )
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      const companies = await Promise.all(
        Array.from(new Set(usersData.map((user) => user.companyId))).map(
          (company) => db.collection("companies").doc(company).get()
        )
      );
      let companiesData = [];
      companies.forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      challengesData.map((challenge) => {
        const userData = usersData.find((user) => user.uid == challenge.uid);
        const companyData = companiesData.find(
          (company) => company.cid == challenge.companyId
        );

        if (!userData || !companyData) return;
        if (userData.disabled) return;

        let lastPart2;

        if (challenge?.part_2) {
          if (challenge.part_2.attempt == 1) {
            lastPart2 = challenge.part_2_answers;
          } else {
            lastPart2 = challenge[`part_2_answers_${challenge.part_2.attempt}`];
          }
        }

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          TELÉFONO: userData.phone,
          DIRECCIÓN: userData.address,
          CIUDAD: userData.city.toUpperCase(),
          REGION: companyData.region,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          EMPRESA: companyData.name,
          ASESOR: companyData.salesman,
          DEPENDIENTES: companyData.workers,
          "PARTE 1": challenge?.part_1
            ? challenge?.part_1?.isReviewed
              ? challenge?.part_1?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 2": challenge?.part_2
            ? challenge?.part_2?.isReviewed
              ? challenge?.part_2?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "PARTE 3": challenge?.part_3
            ? challenge?.part_3?.isReviewed
              ? challenge?.part_3?.isValid
                ? "VALIDO"
                : "NO VALIDO"
              : "NO REVISADO"
            : "NO REALIZADO",
          "RESPUESTAS CORRECTAS": challenge?.part_2
            ? challenge?.part_2?.correctAnswers
            : "NO RESPONDIDO",
          "RESPUESTA 1": lastPart2
            ? lastPart2[0].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 2": lastPart2
            ? lastPart2[1].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 3": lastPart2
            ? lastPart2[2].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 4": lastPart2
            ? lastPart2[3].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "RESPUESTA 5": lastPart2
            ? lastPart2[4].isValid
              ? "CORRECTA"
              : "INCORRECTA"
            : "NO RESPONDIDO",
          "ESTADO RETO": challenge?.isValid ? "VALIDO" : "NO VALIDO",
        });
      });

      const workSheet1 = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet1, "Usuarios");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "REPORTE RETO P13 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getCourseP01_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const Courses = await db
        .collection("certifications")
        .where("period", "==", "p01")
        .get();

      let usersPromise = [];
      Courses.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.data().uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        usersData.push(doc.data());
      });

      let reportData = [];
      Courses.docs.forEach((course, index) => {
        const user = usersData[index];

        if (usersData.length > 0) {
          reportData.push({
            NOMBRE: user.name,
            EMAIL: user.email,
            CIUDAD: user.city,
            EMPRESA: user.companyName,
            TRACKSALES: user.companyId,
            "NOMBRE CURSO": course.data().name,
          });
        }
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P01");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P01 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP02_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const coursesP02 = await db
        .collection("certifications")
        .where("period", "==", "p02")
        .get();
      let courseP02Data = [];
      coursesP02.forEach((doc) => {
        courseP02Data.push(doc.data());
      });

      let usersPromise = [];
      courseP02Data.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        usersData.push(doc.data());
      });

      let reportData = [];
      courseP02Data.forEach((course, index) => {
        const userData = usersData[index];

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 6": course.answers[5].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 7": course.answers[6].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P02");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P02 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP03_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const coursesP02 = await db
        .collection("certifications")
        .where("period", "==", "p03")
        .get();
      let courseP02Data = [];
      coursesP02.forEach((doc) => {
        courseP02Data.push(doc.data());
      });

      let usersPromise = [];
      courseP02Data.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let reportData = [];
      courseP02Data.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);

        if (!userData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 6": course.answers[5].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 7": course.answers[6].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P03");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P03 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP04_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = await db
        .collection("certifications")
        .where("period", "==", "p04")
        .get();
      let courseData = [];
      courses.forEach((doc) => {
        courseData.push(doc.data());
      });

      let usersPromise = [];
      courseData.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let reportData = [];
      courseData.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);

        if (!userData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P04");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P04 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP05_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = await db
        .collection("certifications")
        .where("period", "==", "p05")
        .get();
      let courseData = [];
      courses.forEach((doc) => {
        courseData.push(doc.data());
      });

      let usersPromise = [];
      courseData.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let reportData = [];
      courseData.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);

        if (!userData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P05");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P05 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP06_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = await db
        .collection("certifications")
        .where("period", "==", "p06")
        .get();
      let courseData = [];
      courses.forEach((doc) => {
        courseData.push(doc.data());
      });

      let usersPromise = [];
      courseData.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let reportData = [];
      courseData.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);

        if (!userData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 6": course.answers[5].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P06");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P06 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP07_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = await db
        .collection("certifications")
        .where("period", "==", "p07")
        .get();
      let courseData = [];
      courses.forEach((doc) => {
        courseData.push(doc.data());
      });

      let usersPromise = [];
      courseData.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let reportData = [];
      courseData.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);

        if (!userData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P07");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P07 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP08_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = await db
        .collection("certifications")
        .where("period", "==", "p08")
        .get();
      let courseData = [];
      courses.forEach((doc) => {
        courseData.push(doc.data());
      });

      let usersPromise = [];
      courseData.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let reportData = [];
      courseData.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);

        if (!userData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P08");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P08 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP09_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = await db
        .collection("certifications")
        .where("period", "==", "p09")
        .get();
      let courseData = [];
      courses.forEach((doc) => {
        courseData.push(doc.data());
      });

      let usersPromise = [];
      courseData.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let reportData = [];
      courseData.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);

        if (!userData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P09 P10");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P09 P10 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP11_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = await db
        .collection("certifications")
        .where("period", "==", "p11")
        .get();
      let courseData = [];
      courses.forEach((doc) => {
        courseData.push(doc.data());
      });

      let usersPromise = [];
      courseData.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let reportData = [];
      courseData.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);

        if (!userData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 6": course.answers[5].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P11");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P11 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP12_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = await db
        .collection("certifications")
        .where("period", "==", "p12")
        .get();
      let courseData = [];
      courses.forEach((doc) => {
        courseData.push(doc.data());
      });

      let usersPromise = [];
      courseData.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let reportData = [];
      courseData.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);

        if (!userData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P12");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P12 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP13_2023({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = await db
        .collection("certifications")
        .where("period", "==", "p13")
        .get();
      let courseData = [];
      courses.forEach((doc) => {
        courseData.push(doc.data());
      });

      let usersPromise = [];
      courseData.forEach((course) => {
        usersPromise.push(db.collection("users").doc(course.uid).get());
      });
      let DataPromise = await Promise.all(usersPromise);
      let usersData = [];
      DataPromise.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let reportData = [];
      courseData.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);

        if (!userData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: userData.companyName,
          TRACKSALES: userData.companyId,
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P13");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P13 2023.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP02_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = (
        await db
          .collection("certifications")
          .where("period", "==", "p02")
          .where("year", "==", "2024")
          .get()
      ).docs.map((doc) => doc.data());

      const users = await Promise.all(
        courses.map((course) => db.collection("users").doc(course.uid).get())
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companiesData = [];
      (
        await Promise.all(
          Array.from(new Set(usersData.map((user) => user.companyId))).map(
            (cid) => db.collection("companies").doc(cid).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      courses.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);
        const companyData = companiesData.find(
          (company) => company.cid == course.companyId
        );

        if (!userData) return;
        if (!companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          REGION: companyData.region?.toUpperCase() ?? "",
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P02 P03");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P02 P03 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP04_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = (
        await db
          .collection("certifications")
          .where("period", "==", "p04")
          .where("year", "==", "2024")
          .get()
      ).docs.map((doc) => doc.data());

      if (courses.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        courses.map((course) => db.collection("users").doc(course.uid).get())
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companiesData = [];
      (
        await Promise.all(
          Array.from(new Set(usersData.map((user) => user.companyId))).map(
            (cid) => db.collection("companies").doc(cid).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      courses.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);
        const companyData = companiesData.find(
          (company) => company.cid == course.companyId
        );

        if (!userData) return;
        if (!companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          REGION: companyData.region?.toUpperCase() ?? "",
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P04");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P04 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP05_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = (
        await db
          .collection("certifications")
          .where("period", "==", "p05")
          .where("year", "==", "2024")
          .get()
      ).docs.map((doc) => doc.data());

      if (courses.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        courses.map((course) => db.collection("users").doc(course.uid).get())
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companiesData = [];
      (
        await Promise.all(
          Array.from(new Set(usersData.map((user) => user.companyId))).map(
            (cid) => db.collection("companies").doc(cid).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      courses.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);
        const companyData = companiesData.find(
          (company) => company.cid == course.companyId
        );

        if (!userData) return;
        if (!companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          REGION: companyData.region?.toUpperCase() ?? "",
          ESTADO: course.isReviewed
            ? course.isValid
              ? "VALIDO"
              : "NO VALIDO"
            : "PENDIENTE POR REVISAR",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].optionsSelected[0].text,
          "RESPUESTA 1 ESTADO": course.answers[0].isValid
            ? "CORRECTA"
            : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].optionsSelected[0].text,
          "RESPUESTA 4 ESTADO": course.answers[3].isValid
            ? "CORRECTA"
            : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].optionsSelected[0].text,
          "RESPUESTA 5 ESTADO": course.answers[4].isValid
            ? "CORRECTA"
            : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P05");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P05 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP06_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = (
        await db
          .collection("certifications")
          .where("period", "==", "p06")
          .where("year", "==", "2024")
          .get()
      ).docs.map((doc) => doc.data());

      if (courses.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        courses.map((course) => db.collection("users").doc(course.uid).get())
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companiesData = [];
      (
        await Promise.all(
          Array.from(new Set(usersData.map((user) => user.companyId))).map(
            (cid) => db.collection("companies").doc(cid).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      courses.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);
        const companyData = companiesData.find(
          (company) => company.cid == course.companyId
        );

        if (!userData) return;
        if (!companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          REGION: companyData.region?.toUpperCase() ?? "",
          ESTADO: course.isReviewed
            ? course.isValid
              ? "VALIDO"
              : "NO VALIDO"
            : "PENDIENTE POR REVISAR",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].optionsSelected[0].text,
          "RESPUESTA 1 ESTADO": course.answers[0].isValid
            ? "CORRECTA"
            : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].optionsSelected[0].text,
          "RESPUESTA 4 ESTADO": course.answers[3].isValid
            ? "CORRECTA"
            : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].optionsSelected[0].text,
          "RESPUESTA 5 ESTADO": course.answers[4].isValid
            ? "CORRECTA"
            : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P06");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P06 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP07_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = (
        await db
          .collection("certifications")
          .where("period", "==", "p07")
          .where("year", "==", "2024")
          .get()
      ).docs.map((doc) => doc.data());

      if (courses.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        courses.map((course) => db.collection("users").doc(course.uid).get())
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companiesData = [];
      (
        await Promise.all(
          Array.from(new Set(usersData.map((user) => user.companyId))).map(
            (cid) => db.collection("companies").doc(cid).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      courses.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);
        const companyData = companiesData.find(
          (company) => company.cid == course.companyId
        );

        if (!userData) return;
        if (!companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          REGION: companyData.region?.toUpperCase() ?? "",
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 6": course.answers[5].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P07");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P07 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP08_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = (
        await db
          .collection("certifications")
          .where("period", "==", "p08")
          .where("year", "==", "2024")
          .get()
      ).docs.map((doc) => doc.data());

      if (courses.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        courses.map((course) => db.collection("users").doc(course.uid).get())
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companiesData = [];
      (
        await Promise.all(
          Array.from(new Set(usersData.map((user) => user.companyId))).map(
            (cid) => db.collection("companies").doc(cid).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      courses.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);
        const companyData = companiesData.find(
          (company) => company.cid == course.companyId
        );

        if (!userData) return;
        if (!companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          REGION: companyData.region?.toUpperCase() ?? "",
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P08");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P08 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP09_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = (
        await db
          .collection("certifications")
          .where("period", "==", "p09")
          .where("year", "==", "2024")
          .get()
      ).docs.map((doc) => doc.data());

      if (courses.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        courses.map((course) => db.collection("users").doc(course.uid).get())
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companiesData = [];
      (
        await Promise.all(
          Array.from(new Set(usersData.map((user) => user.companyId))).map(
            (cid) => db.collection("companies").doc(cid).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      courses.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);
        const companyData = companiesData.find(
          (company) => company.cid == course.companyId
        );

        if (!userData) return;
        if (!companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          REGION: companyData.region?.toUpperCase() ?? "",
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 6": course.answers[5].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 7": course.answers[6].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P09");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P09 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP10_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = (
        await db
          .collection("certifications")
          .where("period", "==", "p10")
          .where("year", "==", "2024")
          .get()
      ).docs.map((doc) => doc.data());

      if (courses.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        courses.map((course) => db.collection("users").doc(course.uid).get())
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companiesData = [];
      (
        await Promise.all(
          Array.from(new Set(usersData.map((user) => user.companyId))).map(
            (cid) => db.collection("companies").doc(cid).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      courses.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);
        const companyData = companiesData.find(
          (company) => company.cid == course.companyId
        );

        if (!userData) return;
        if (!companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          REGION: companyData.region?.toUpperCase() ?? "",
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 6": course.answers[5].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 7": course.answers[6].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P10");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P10 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP12_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = (
        await db
          .collection("certifications")
          .where("period", "==", "p12")
          .where("year", "==", "2024")
          .get()
      ).docs.map((doc) => doc.data());

      if (courses.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        courses.map((course) => db.collection("users").doc(course.uid).get())
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companiesData = [];
      (
        await Promise.all(
          Array.from(new Set(usersData.map((user) => user.companyId))).map(
            (cid) => db.collection("companies").doc(cid).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      courses.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);
        const companyData = companiesData.find(
          (company) => company.cid == course.companyId
        );

        if (!userData) return;
        if (!companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          REGION: companyData.region?.toUpperCase() ?? "",
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P12");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P12 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async getCourseP13_2024({}, to) {
    try {
      const XLSX = require("xlsx");
      const courses = (
        await db
          .collection("certifications")
          .where("period", "==", "p13")
          .where("year", "==", "2024")
          .get()
      ).docs.map((doc) => doc.data());

      if (courses.length === 0) {
        alert("No hay datos");
        return;
      }

      const users = await Promise.all(
        courses.map((course) => db.collection("users").doc(course.uid).get())
      );
      let usersData = [];
      users.forEach((doc) => {
        if (doc.exists) {
          usersData.push(doc.data());
        }
      });

      let companiesData = [];
      (
        await Promise.all(
          Array.from(new Set(usersData.map((user) => user.companyId))).map(
            (cid) => db.collection("companies").doc(cid).get()
          )
        )
      ).forEach((doc) => {
        if (doc.exists) {
          companiesData.push(doc.data());
        }
      });

      let reportData = [];
      courses.forEach((course) => {
        const userData = usersData.find((user) => user.uid == course.uid);
        const companyData = companiesData.find(
          (company) => company.cid == course.companyId
        );

        if (!userData) return;
        if (!companyData) return;
        if (userData.disabled) return;

        reportData.push({
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CÉDULA: userData.cc,
          CIUDAD: userData.city.toUpperCase(),
          EMPRESA: companyData.name,
          NIT: companyData.nit,
          TRACKSALES: companyData.cid,
          REGION: companyData.region?.toUpperCase() ?? "",
          ESTADO: course.isValid ? "VALIDO" : "NO VALIDO",
          "RESPUESTAS CORRECTAS": course.correctAnswers,
          "RESPUESTA 1": course.answers[0].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 2": course.answers[1].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 3": course.answers[2].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 4": course.answers[3].isValid ? "CORRECTA" : "INCORRECTA",
          "RESPUESTA 5": course.answers[4].isValid ? "CORRECTA" : "INCORRECTA",
        });
      });

      const workSheet = XLSX.utils.json_to_sheet(reportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Curso P13");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, "Reporte Academia Mars P13 2024.xlsx");
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getRedemptionsByPoints({}, to) {
    const XLSX = require("xlsx");
    const redemptionsFire = await db
      .collection("challenges_2023_redemptions")
      .get();

    const users = [];
    const redemptions = [];

    redemptionsFire.docs.map((redemption) => {
      redemptions.push(redemption.data());
    });

    const usersUnique = redemptions.filter((redemption, index) => {
      return redemptions.findIndex((r) => r.uid == redemption.uid) == index;
    });

    const usersFire = await Promise.all(
      usersUnique.map((user) => db.collection("users").doc(user.uid).get())
    );
    usersFire.forEach((user) => {
      if (user.exists) {
        users.push(user.data());
      }
    });

    const finalArray = [];
    redemptions.map((redemption) => {
      const userData = users.find((user) => user.uid == redemption.uid);
      if (userData) {
        const obj = {
          FECHA: new Date(redemption.redemptionDate).toLocaleDateString(),
          CIUDAD: userData.city.toUpperCase(),
          TRACKSALES: userData.companyId,
          EMPRESA: userData.companyName,
          CEDULA: userData.cc,
          NOMBRE: userData.name,
          EMAIL: userData.email,
          CELULAR: userData.phone,
          DIRECCION: userData.address,
          PREMIO: redemption.awardName,
        };

        finalArray.push(obj);
      }
    });

    const workSheet = XLSX.utils.json_to_sheet(finalArray);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "INFO");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "REPORTE_REDENCIONES_POR_PUNTOS.xlsx");
  },

  async getCompanyInstallments({}, to) {
    const XLSX = require("xlsx");
    const companiesSnap = await db
      .collection("companies")
      .where("cid", "!=", "900123123-001")
      .get();

    const companies = companiesSnap.docs.map((doc) => doc.data());

    const goalsBimesterPromises = companies.map((company) =>
      db
        .collection("companies")
        .doc(company.cid)
        .collection("newGoals")
        .doc("2024")
        .get()
    );

    const goalsPeriodPromises = companies.map((company) =>
      db
        .collection("companies")
        .doc(company.cid)
        .collection("newGoals")
        .doc("2024_periods")
        .get()
    );

    const goalsBimester = await Promise.all(goalsBimesterPromises);
    const goalsPeriod = await Promise.all(goalsPeriodPromises);

    const reportData = companies.map((company, idx) => {
      const data = {
        NIT: company.nit,
        TRACKSALES: company.cid,
        EMPRESA: company.name,
        REGION: company.region,
        CIUDAD: company.city,
        DIRECCIÓN: company.address,
        ASESOR: company.salesman,
        "TELÉFONO ASESOR": company.salesmanPhone,
        "CUPOS TOTALES": company.maxWorkers,
        "CUPOS DISPONIBLES": company.maxWorkers - company.workers,
        "CUPOS ASIGNADOS": company.workers,
      };

      if (goalsBimester[idx].exists) {
        const bimester = Object.values(goalsBimester[idx].data());
        bimester.forEach((doc, i) => {
          data[`BIMESTRE_${i + 1}_DRY`] = doc.dry;
          data[`BIMESTRE_${i + 1}_WET`] = doc.wet;
          data[`BIMESTRE_${i + 1}_C&T`] = doc.snack;
          data[`BIMESTRE_${i + 1}_TOTAL`] = doc.total;
        });
      }

      if (goalsPeriod[idx].exists) {
        const period = Object.values(goalsPeriod[idx].data());
        period.forEach((doc, i) => {
          data[`PERIODO_${i + 1}_DRY`] = doc.dry;
          data[`PERIODO_${i + 1}_WET`] = doc.wet;
          data[`PERIODO_${i + 1}_C&T`] = doc.snack;
          data[`PERIODO_${i + 1}_TOTAL`] = doc.total;
        });
      }

      return data;
    });

    const workSheet = XLSX.utils.json_to_sheet(reportData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "INFO");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "REPORTE CUOTAS DE EMPRESAS.xlsx");
  },
};
export default {
  namespaced: true,
  actions,
};
